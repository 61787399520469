import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { FiEdit } from 'react-icons/fi'
import { FiPlus } from 'react-icons/fi'
import { CgClose } from 'react-icons/cg'
import { RiFileCopyLine } from 'react-icons/ri'

import styles from './Card.module.scss'

const FormContainer = ({
  children,
  className,
  onClick,
  onClickAdd,
  onClickDelete,
  onClickOpenModal,
  array,
  disabled,
  selectAdmin,
}) => {
  const valueDay = useSelector((state) => state.adminSlice.valueDay)
  return (
    <div
      className={classNames(
        classNames(styles.wrap, { [styles.wrapImg]: array === 'images' })
      )}
    >
      <div className={styles.control}>
        {(valueDay === 0 && selectAdmin === 'Jiurnal') ||
        (valueDay === 31 && selectAdmin === 'Jiurnal') ? null : (
          <div
            className={classNames(styles.controlBtn, {
              [styles.disabled]: disabled,
            })}
            onClick={onClick}
          >
            <FiEdit />
          </div>
        )}
        {(valueDay === 0 && selectAdmin === 'Jiurnal') ||
        (valueDay === 31 && selectAdmin === 'Jiurnal') ? null : (
          <div
            className={classNames(styles.controlBtn, {
              [styles.disabled]: disabled,
            })}
            onClick={onClickOpenModal}
          >
            <FiPlus />
          </div>
        )}
        <div
          className={classNames(styles.controlBtn, {
            [styles.disabled]: disabled,
          })}
          onClick={onClickDelete}
        >
          <CgClose />
        </div>
        <div
          className={classNames(styles.controlBtn, {
            [styles.disabled]: disabled,
          })}
          onClick={onClickAdd}
        >
          <RiFileCopyLine />
        </div>
      </div>
      <div>{children}</div>
    </div>
  )
}

export default FormContainer
