import { createSlice } from '@reduxjs/toolkit'

const clientSlice = createSlice({
  name: 'client',
  initialState: {
    viewDay: [],
    viewDiary: [],
    testA: undefined,
    testB: undefined,
    loadingTabl: '',
  },
  reducers: {
    getViewDay: (state, action) => {},
    getViewDiary: (state, action) => {},
    setViewDay: (state, action) => {
      state.viewDay = action.payload
    },
    setViewDiary: (state, action) => {
      state.viewDiary = action.payload
    },
    getSaveDiary: (state, action) => {},
    getSaveTest: (state, action) => {},
    getTestA: (state, action) => {},
    setTestA: (state, action) => {
      state.testA = action.payload
    },
    getTestB: (state, action) => {},
    setTestB: (state, action) => {
      state.testB = action.payload
    },
    setloadingTabl: (state, action) => {
      state.loadingTabl = action.payload
    },
  },
})

export const {
  getViewDay,
  setViewDay,
  setViewDiary,
  getViewDiary,
  getSaveDiary,
  getSaveTest,
  setTestA,
  getTestA,
  setTestB,
  getTestB,
  setloadingTabl,
} = clientSlice.actions
export default clientSlice.reducer
