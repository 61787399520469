import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill'
import ReactDOMServer from 'react-dom/server'
import parse from 'html-react-parser'
import { useDispatch } from 'react-redux'
import { setArr } from '../../redux/Admin/adminSlice'

import './EditorHtml.css'
import B from '../../assets/img/Editor/B'
import I from '../../assets/img/Editor/I'
import U from '../../assets/img/Editor/U'
import Link from '../../assets/img/Editor/Link'
import S from '../../assets/img/Editor/S'
import styles from './Editor.module.scss'

const icons = ReactQuill.Quill.import('ui/icons')
icons['bold'] = ReactDOMServer.renderToString(<B />)
icons['italic'] = ReactDOMServer.renderToString(<I />)
icons['underline'] = ReactDOMServer.renderToString(<U />)
icons['strike'] = ReactDOMServer.renderToString(<S />)
icons['link'] = ReactDOMServer.renderToString(<Link />)

export const EditorHtml = (props) => {
  const { edit, id, textCard, arr } = props
  const [valueEditor, setValueEditor] = useState(textCard)
  const dispatch = useDispatch()
  const saveData = () => {
    dispatch(
      setArr(
        arr.map((value) =>
          value.id === id
            ? { ...value, title: valueEditor, edit: false }
            : { ...value }
        )
      )
    )
  }

  useEffect(() => {
    setValueEditor(textCard)
  }, [edit])
  return (
    <>
      {edit ? (
        <>
          <ReactQuill
            placeholder={'Написать'}
            formats={[
              'header',
              'font',
              'size',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
              'link',
              'video',
              'align',
            ]}
            modules={{
              toolbar: [
                ['italic', 'bold', 'underline', 'strike'],
                [{ header: [] }],
                [{ align: [] }],
                ['link'],
              ],
            }}
            theme="snow"
            value={valueEditor}
            onChange={setValueEditor}
          />
          <div className={styles.innerSave}>
            <div
              onClick={saveData}
              className={styles.save}
            >
              Сохранить
            </div>
          </div>
        </>
      ) : (
        <div className={styles.text}>{parse(`${textCard}`)}</div>
      )}
    </>
  )
}

export default EditorHtml
