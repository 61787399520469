// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

ul,
ol {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
  cursor: pointer;
  border: none;
  background: inherit;
}

.yarl__navigation_next,
.yarl__navigation_prev {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAGA;EACE,sBAAA;AAFF;;AAKA;;;EAGE,mBAAA;AAFF;;AAKA;;EAEE,UAAA;AAFF;;AAKA;;;;;;;;;;;;;;;EAeE,SAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,eAAA;EACA,cAAA;AAFF;;AAKA;;;;EAIE,aAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;AAFF;;AAKA;;EAEE,wBAAA;AAFF","sourcesContent":["@import 'scss/vars';\n@import 'scss/media';\n\nhtml {\n  box-sizing: border-box;\n}\n\n*,\n*::after,\n*::before {\n  box-sizing: inherit;\n}\n\nul,\nol {\n  padding: 0;\n}\n\nbody,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\np,\nul,\nol li,\nfigure,\nfigcaption,\nblockquote,\ndl,\ndd {\n  margin: 0;\n}\n\nul {\n  list-style: none;\n}\n\nimg {\n  max-width: 100%;\n  display: block;\n}\n\ninput,\nbutton,\ntextarea,\nselect {\n  font: inherit;\n  cursor: pointer;\n  border: none;\n  background: inherit;\n}\n\n.yarl__navigation_next,\n.yarl__navigation_prev {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
