import './scss/app.scss'
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { NextUIProvider } from '@nextui-org/system'

import AdminPage from './routes/AdminPage/AdminPage'
import { ViewDay } from './routes/ViewDay/ViewDay'
import { ViewDiary } from './routes/ViewDiary/ViewDiary'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { store } from './redux/store'
import { Provider } from 'react-redux'
import { TestB } from './components/TestB/TestB'
import { DiaryJournal } from './routes/DiaryJournal/DiaryJournal'
import { TestA } from './components/TestA/TestA'
import { Statistic } from './components/Statistic/Statistic'

function App() {
  return (
    <Provider store={store}>
      <NextUIProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/diary-journal"
              element={<DiaryJournal />}
            />
            <Route
              path="/day"
              element={<ViewDay />}
            />
            <Route
              path="/admin"
              element={<AdminPage />}
            />
            <Route
              path="admin/stat"
              element={<Statistic />}
            />
            <Route
              path="/diary-journal/:day/:id/:sd"
              element={<ViewDiary />}
            />
            <Route
              path="/questions/a/:day/:id"
              element={<TestA />}
            />
            <Route
              path="/questions/b/:day/:id"
              element={<TestB />}
            />
          </Routes>
          <ToastContainer position="bottom-right" />
        </BrowserRouter>
      </NextUIProvider>
    </Provider>
  )
}

export default App
