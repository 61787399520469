import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import uniqid from 'uniqid'
import FormContainer from '../layout/FormContainer/Card'
import styles from './BlockMain.module.scss'
import { TitleContent } from '../TitleContent/TitleContent'
import ImagesContent from '../ImagesContent/ImagesContent'
import EditorHtml from '../Editor/EditorHtml'
import Table from '../Table/Table'
import { ListContent } from '../ListContent/ListContent'
import { Modal } from '../Modal/Modal'
import {
  getSaveDayPhoto,
  setArr,
  setValueDay,
  getSaveAdminDiary,
} from '../../redux/Admin/adminSlice'
import { getSaveTest } from '../../redux/Client/clientSlice'
import Selected from '../Selected'
import { dataArr } from '../../data'
import { day } from '../../data'
import CreateTest from '../CreateTest/CreateTest'

export const BlockMain = (props) => {
  const { selectAdmin } = props
  const [selectDay, setSelectDay] = useState('')
  const [test, setTest] = useState([
    {
      question: '',
      answers: '',
      id: uniqid(),
    },
  ])
  const arr = useSelector((state) => state.adminSlice.mocArr)
  const valueDay = useSelector((state) => state.adminSlice.valueDay)
  const dispatch = useDispatch()
  const [isOpenModal, setIsOpenModal] = useState({
    isOpen: false,
    id: 0,
    array: '',
  })

  function addCardToArray(inputNum, id, arr) {
    const index = arr.findIndex((el) => el.id === id)
    return index !== -1
      ? [...arr.slice(0, index + 1), inputNum, ...arr.slice(index + 1)]
      : arr
  }

  const editCardHandler = (id) => {
    dispatch(
      setArr(
        arr.map((card) =>
          card.id === id ? { ...card, edit: !card.edit } : { ...card }
        )
      )
    )
  }

  const deleteCardHandler = (id) => {
    dispatch(setArr(arr.filter((value) => value.id !== id)))
  }

  const handleChange = (value) => {
    setSelectDay(value)
  }

  const addCardHandler = (id, array) => {
    if (array === 'images') {
      const card = arr.filter((value) => (value.id === id ? value : null))
      dispatch(
        setArr(
          addCardToArray(
            {
              title: '',
              id: uniqid(),
              edit: false,
              images: card[0].images,
              table: [],
              list: [],
              array: array,
              isDot: true,
            },
            id,
            arr
          )
        )
      )
    } else if (array === 'tabl') {
      const card = arr.filter((value) => (value.id === id ? value : null))
      dispatch(
        setArr(
          addCardToArray(
            {
              title: '',
              id: uniqid(),
              edit: false,
              images: '',
              table: card[0].table,
              list: [],
              array: array,
              isDot: true,
            },
            id,
            arr
          )
        )
      )
    } else if (array === 'list') {
      const card = arr.filter((value) => (value.id === id ? value : null))
      dispatch(
        setArr(
          addCardToArray(
            {
              title: '',
              id: uniqid(),
              edit: false,
              images: '',
              table: [],
              list: card[0].list,
              array: array,
              isDot: card[0].isDot,
            },
            id,
            arr
          )
        )
      )
    } else {
      const card = arr.filter((value) => (value.id === id ? value : null))
      dispatch(
        setArr(
          addCardToArray(
            {
              title: card[0].title,
              id: uniqid(),
              edit: false,
              images: '',
              table: [],
              list: [],
              array: array,
              isDot: true,
            },
            id,
            arr
          )
        )
      )
    }
  }

  const addCardCleenHandler = (id, valueArr, value) => {
    const checkValue = () => {
      switch (value) {
        case 'Заголовок':
          return 'title'
        case 'Текстовый блок':
          return 'text'
        case 'Загрузить изображение':
          return 'images'
        case 'Таблица':
          return 'tabl'
        case 'Список':
          return 'list'
        default:
          break
      }
    }

    const checkTitle = () => {
      switch (value) {
        case 'Заголовок':
          return 'Заголовок дня'
        case 'Текстовый блок':
          return 'Текст дня'
        default:
          return ''
      }
    }

    dispatch(
      setArr(
        addCardToArray(
          {
            title: checkTitle(),
            id: uniqid(),
            edit: false,
            images: '',
            table: [],
            list: [],
            array: checkValue(),
            isDot: true,
          },
          id,
          arr
        )
      )
    )
  }

  const renderCard = (value) => {
    if (value.array === 'images') {
      return (
        <ImagesContent
          arr={arr}
          id={value.id}
          edit={value.edit}
          imagesCard={value.images}
        />
      )
    } else if (value.array === 'text') {
      return (
        <EditorHtml
          arr={arr}
          id={value.id}
          edit={value.edit}
          textCard={value.title}
        />
      )
    } else if (value.array === 'tabl') {
      return (
        <Table
          id={value.id}
          editPanel={value.edit}
          arr={arr}
          tablCard={value.table}
        />
      )
    } else if (value.array === 'list') {
      return (
        <ListContent
          id={value.id}
          edit={value.edit}
          listCard={value.list}
          arr={arr}
          isDote={value.isDot}
        />
      )
    } else {
      return (
        <TitleContent
          id={value.id}
          arr={arr}
          edit={value.edit}
          titleCard={value.title}
        />
      )
    }
  }

  const saveDayHandler = () => {
    if (selectAdmin === 'Day') {
      dispatch(getSaveDayPhoto({ dayNum: selectDay || valueDay, content: arr }))
      if (valueDay === 0) {
        dispatch(setArr(dataArr))
        dispatch(setValueDay(0))
      }
    } else if (selectAdmin === 'Jiurnal') {
      if (valueDay === 0 || valueDay === 31) {
        dispatch(
          getSaveTest({
            num: valueDay,
            content: { test, inputValue: '' },
          })
        )
      } else {
        dispatch(
          getSaveAdminDiary({
            dayNum: selectDay || valueDay,
            content: arr,
          })
        )
      }
    }
  }

  return (
    <div className={styles.wrap}>
      {(valueDay === 0 && selectAdmin === 'Jiurnal') ||
      (valueDay === 31 && selectAdmin === 'Jiurnal')
        ? test.map((value) => (
            <CreateTest
              selectAdmin={selectAdmin}
              idCard={value.id}
              arrCard={test}
              setArrCard={setTest}
              key={value.id}
            ></CreateTest>
          ))
        : arr.map((value, index) => (
            <FormContainer
              onClickOpenModal={() =>
                setIsOpenModal({
                  isOpen: true,
                  id: value.id,
                  array: value.array,
                })
              }
              onClick={() => editCardHandler(value.id)}
              onClickAdd={() => addCardHandler(value.id, value.array)}
              onClickDelete={() => {
                deleteCardHandler(value.id)
              }}
              key={index}
              array={value.array}
              selectAdmin={selectAdmin}
            >
              {renderCard(value)}
            </FormContainer>
          ))}
      {selectAdmin === 'Jiurnal' ? null : valueDay ? (
        <div className={styles.day}>{`День ${valueDay}`}</div>
      ) : (
        <div className={styles.day}>
          <Selected
            data={day}
            handleChange={handleChange}
            value={'Выбрать день'}
          />
        </div>
      )}
      <div
        onClick={saveDayHandler}
        className={styles.btn}
      >
        Отправить
      </div>
      <Modal
        addCardCleenHandler={(value) =>
          addCardCleenHandler(isOpenModal.id, isOpenModal.array, value)
        }
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
    </div>
  )
}
