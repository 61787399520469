// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/AnyConv.com__heliosext_bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/AnyConv.com__heliosext_bold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Heli";
  font-style: normal;
  font-weight: 400;
  src: local("Heli"), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
}
html,
body {
  height: 100%;
}

body {
  font-family: Open Sans;
  font-weight: 500;
  font-style: normal;
  margin: 0 auto;
}

.Toastify__toast {
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/scss/app.scss"],"names":[],"mappings":"AAGA;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mIAAA;AAFF;AAOA;;EAEE,YAAA;AALF;;AAQA;EACE,sBAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;AALF;;AAQA;EACE,YAAA;AALF","sourcesContent":["@import '_vars.scss';\n@import '_media.scss';\n\n@font-face {\n  font-family: 'Heli';\n  font-style: normal;\n  font-weight: 400;\n  src: local('Heli'),\n    url('../fonts/AnyConv.com__heliosext_bold.woff2') format('woff2'),\n    url('../fonts/AnyConv.com__heliosext_bold.woff') format('woff');\n}\n\nhtml,\nbody {\n  height: 100%;\n}\n\nbody {\n  font-family: Open Sans;\n  font-weight: 500;\n  font-style: normal;\n  margin: 0 auto;\n}\n\n.Toastify__toast {\n  height: 20px; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
