import { CSVLink } from 'react-csv'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './Statistic.module.scss'
import VerticalDiagram from '../VerticalDiagram/VerticalDiagram'
import { day } from '../../data'
import DoughnutDiagram from '../DoughnutDiagram/DoughnutDiagram'
import {
  getKpi1AdminDiary,
  getKpi2AdminDiary,
  getKpi3AdminDiary,
  getKpi4AdminDiary,
  getKpi5AdminDiary,
  getKpi6AdminDiary,
  setValueDay,
} from '../../redux/Admin/adminSlice'
import { AiFillBackward } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

export const Statistic = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const kpi1 = useSelector((state) => state.adminSlice.kpi1)
  const kpi2 = useSelector((state) => state.adminSlice.kpi2)
  const kpi3 = useSelector((state) => state.adminSlice.kpi3)
  const kpi4 = useSelector((state) => state.adminSlice.kpi4)
  const kpi5 = useSelector((state) => state.adminSlice.kpi5)
  const kpi6 = useSelector((state) => state.adminSlice.kpi6)

  const dataKpi1 = {
    labels: kpi1?.reduce(
      (accumulator, currentValue) => accumulator.concat(currentValue[0]),
      []
    ),
    datasets: [
      {
        label: 'Процент',
        data: kpi1?.reduce(
          (accumulator, currentValue) => accumulator.concat(currentValue[1]),
          []
        ),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  }

  const dataKpi2 = {
    labels: ['Кол-во заполненных дневников', 'Кол-во платных пользователей'],
    datasets: [
      {
        label: 'Число',
        data: kpi2,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(0, 128, 0, 0.2)',
          'rgba(255, 165, 0, 0.2)',
          'rgba(255, 255, 0, 0.2)',
          'rgba(238, 130, 238, 0.2)',
          'rgba(165, 42, 42, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235,1)',
          'rgba(0, 128, 0, 1)',
          'rgba(255, 165, 0, 1)',
          'rgba(255, 255, 0, 1)',
          'rgba(238, 130, 238, 1)',
          'rgba(165, 42, 42, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }

  const dataKpi5 = {
    labels: kpi5?.reduce(
      (accumulator, currentValue) =>
        accumulator.concat(
          currentValue[0] !== 'null' ? currentValue[0] : 'Другое'
        ),
      []
    ),
    datasets: [
      {
        label: 'Количество',
        data: kpi5?.reduce(
          (accumulator, currentValue) => accumulator.concat(currentValue[1]),
          []
        ),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(0, 128, 0, 0.2)',
          'rgba(255, 165, 0, 0.2)',
          'rgba(255, 255, 0, 0.2)',
          'rgba(238, 130, 238, 0.2)',
          'rgba(165, 42, 42, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(0, 128, 0, 1)',
          'rgba(255, 165, 0, 1)',
          'rgba(255, 255, 0, 1)',
          'rgba(238, 130, 238,1)',
          'rgba(165, 42, 42, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }

  useEffect(() => {
    dispatch(getKpi1AdminDiary())
    dispatch(getKpi2AdminDiary())
    dispatch(getKpi3AdminDiary())
    dispatch(getKpi4AdminDiary())
    dispatch(getKpi5AdminDiary())
    dispatch(getKpi6AdminDiary())
  }, [])

  return (
    <div className={styles.wrap}>
      <div className={styles.title}>
        <span
          className={styles.back}
          onClick={() => {
            navigate('/admin')
            dispatch(setValueDay(0))
          }}
        >
          <AiFillBackward />
        </span>
        Cтатистика
      </div>
      <div className={styles.diagram}>
        <div className={styles.titleItem}>
          {`1) Процент пользователей от общего числа платных подписчиков, которые
          не заходили два и более дней по каждому из дней задания.`}
        </div>
        {kpi1?.length ? (
          <VerticalDiagram data={dataKpi1} />
        ) : (
          <div style={{ opacity: 0.7 }}>Нет данных</div>
        )}
      </div>
      <div className={styles.diagram}>
        <div className={styles.titleItem}>
          {` 2) Количество заполненных дневников / Количество платных пользователей`}
        </div>
        {kpi2?.length ? (
          <DoughnutDiagram data={dataKpi2} />
        ) : (
          <div style={{ opacity: 0.7 }}>Нет данных</div>
        )}
      </div>
      <div className={styles.diagram}>
        <div className={styles.titleItem}>
          {`3) Конверсия по количеству людей оплатило/нажало start  * 100%`}
        </div>
        {kpi3 ? (
          <div className={styles.number}>{`${(
            (kpi3?.paid_count / kpi3?.start_count) *
            100
          ).toFixed(2)}%`}</div>
        ) : (
          <div style={{ opacity: 0.7 }}>Нет данных</div>
        )}
      </div>
      <div className={styles.diagram}>
        <div className={styles.innerCsv}>
          <div className={styles.titleItem}>
            {`4) Топ людей, которые приводят чаще всего других рефералов`}
          </div>
          <CSVLink
            data={kpi4}
            filename={'my-file.csv'}
            target="_blank"
          >
            Сохранить данные
          </CSVLink>
        </div>
        <table className={styles.table}>
          <tbody>
            <tr>
              <td>Пользователь</td>
              <td>Количество людей</td>
            </tr>
            {kpi4?.map((value, index) => (
              <tr key={index}>
                <td>{value.username}</td>
                <td>{value.referals_amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.diagram}>
        <div className={styles.titleItem}>{` 5) Откуда о нас узнали`}</div>
        {kpi5?.length ? (
          <DoughnutDiagram data={dataKpi5} />
        ) : (
          <div style={{ opacity: 0.7 }}>Нет данных</div>
        )}
      </div>
      <div className={styles.diagram}>
        <div
          className={styles.titleItem}
        >{` 6) Пользователи, которые не оплатили, но нажали на start`}</div>
        <CSVLink
          data={
            kpi6?.length
              ? kpi6?.reduce(function (result, user) {
                  return [...result, { user: user }]
                }, [])
              : []
          }
          filename={'my-file.csv'}
          target="_blank"
        >
          Сохранить данные
        </CSVLink>
      </div>
    </div>
  )
}
