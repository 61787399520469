// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/img.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImagesContent_imageItemBtnWrapper__ObHTD {
  display: flex;
  justify-content: center;
  gap: 50px;
}
.ImagesContent_imageItemBtnWrapper__ObHTD button {
  height: 16px;
  width: 16px;
}
.ImagesContent_imageItemBtnWrapper__ObHTD button:hover {
  opacity: 80%;
}

.ImagesContent_uploadImageWrapper__HQ3Yw {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ImagesContent_btn__SMjMn {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center center;
  width: 161px;
  height: 146px;
}

.ImagesContent_imageItem__XjgU6 {
  width: 100%;
  height: 185px;
}

.ImagesContent_img__OvaUZ {
  object-fit: contain;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/ImagesContent/ImagesContent.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,SAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;AACJ;AACE;EACE,YAAA;AACJ;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,yDAAA;EACA,4BAAA;EACA,kCAAA;EACA,YAAA;EACA,aAAA;AAAF;;AAGA;EACE,WAAA;EACA,aAAA;AAAF;;AAGA;EACE,mBAAA;EACA,WAAA;EACA,YAAA;AAAF","sourcesContent":[".imageItemBtnWrapper {\n  display: flex;\n  justify-content: center;\n  gap: 50px;\n\n  button {\n    height: 16px;\n    width: 16px;\n  }\n  button:hover {\n    opacity: 80%;\n  }\n}\n\n.uploadImageWrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.btn {\n  background-image: url('../../assets/img/img.svg');\n  background-repeat: no-repeat;\n  background-position: center center;\n  width: 161px;\n  height: 146px;\n}\n\n.imageItem {\n  width: 100%;\n  height: 185px;\n}\n\n.img {\n  object-fit: contain;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageItemBtnWrapper": `ImagesContent_imageItemBtnWrapper__ObHTD`,
	"uploadImageWrapper": `ImagesContent_uploadImageWrapper__HQ3Yw`,
	"btn": `ImagesContent_btn__SMjMn`,
	"imageItem": `ImagesContent_imageItem__XjgU6`,
	"img": `ImagesContent_img__OvaUZ`
};
export default ___CSS_LOADER_EXPORT___;
