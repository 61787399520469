import React, { useState } from 'react'
import styles from './CreateTest.module.scss'
import FormContainer from '../layout/FormContainer/Card'
import uniqid from 'uniqid'
import RadioInput from '../RadioInput/RadioInput'
const CreateTest = (props) => {
  const { idCard, arrCard, setArrCard, selectAdmin } = props
  const [quest, setQuest] = useState('')
  const [save, setSave] = useState(false)
  function addCardToArray(inputNum, id, arr) {
    const index = arr.findIndex((el) => el.id === id)
    return index !== -1
      ? [...arr.slice(0, index + 1), inputNum, ...arr.slice(index + 1)]
      : arr
  }

  const deleteCardHandler = (id) => {
    setArrCard(arrCard.filter((value) => value.id !== id))
  }

  const addCardHandler = (id) => {
    setArrCard(
      addCardToArray(
        {
          question: '',
          answers: '',
          id: uniqid(),
        },
        id,
        arrCard
      )
    )
  }
  return (
    <FormContainer
      onClickAdd={() => addCardHandler(idCard)}
      onClickDelete={() => {
        deleteCardHandler(idCard)
      }}
      key={idCard}
      selectAdmin={selectAdmin}
    >
      {save ? (
        <div>{quest}</div>
      ) : (
        <>
          <input
            className={styles.input}
            value={quest}
            onChange={(e) => setQuest(e.target.value)}
            type="text"
            placeholder="Создайте вопрос"
          />
          <RadioInput disabled />
          <div className={styles.innerSave}>
            <div
              onClick={() => {
                setSave(true)
                setArrCard(
                  arrCard.map((card) =>
                    card.id === idCard
                      ? { ...card, question: quest }
                      : { ...card }
                  )
                )
              }}
              className={styles.save}
            >
              Сохранить
            </div>
          </div>
        </>
      )}
    </FormContainer>
  )
}

export default CreateTest
