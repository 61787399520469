// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 100px;
  margin: 0 auto;
  max-width: 1440px;
}

.innerContent {
  display: flex;
  gap: 19px;
}`, "",{"version":3,"sources":["webpack://./src/routes/AdminPage/AdminPage.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,cAAA;EACA,iBAAA;AADF;;AAGA;EACE,aAAA;EACA,SAAA;AAAF","sourcesContent":["@import '../../scss/vars';\n\n.container {\n  padding: 100px;\n  margin: 0 auto;\n  max-width: 1440px;\n}\n.innerContent {\n  display: flex;\n  gap: 19px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
