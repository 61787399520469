import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'html-react-parser'
import { getViewDay } from '../../redux/Client/clientSlice'
import classNames from 'classnames'
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import Loader from '../../components/Loader'
import ProgressBar from '../../components/ProgressBar/ProgressBar'
import zoom from '../../assets/img/zoom.svg'
import down from '../../assets/img/dow.svg'
import ruka from '../../assets/img/ruka.png'
import styles from './ViewDay.module.scss'

export const ViewDay = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const imageContainerRefs = useRef([])

  const viewDay = useSelector((state) => state.clientSlice.viewDay)

  const [scrollStatus, setScrollStatus] = useState({})
  const [open, setOpen] = useState(false)
  const [srcImages, setSrcImages] = useState('')
  const searchParams = new URLSearchParams(location.search)
  const day = Number(searchParams.get('day'))
  const userId = searchParams.get('userId')
  const handleDownloadClick = (url, index) => {
    const link = document.createElement('a')
    link.href = url
    link.download = `images${index}.png`
    link.click()
  }

  const hasHorizontalScroll = (element) => {
    console.log(element)
    return element.scrollWidth > element.clientWidth
  }

  useEffect(() => {
    const updatedScrollStatus = {}
    imageContainerRefs.current.forEach((containerRef, index) => {
      const containerElement = containerRef.current
      if (containerElement) {
        updatedScrollStatus[index] = hasHorizontalScroll(containerElement)
      }
    })
    setScrollStatus(updatedScrollStatus)
  }, [viewDay])

  const handleScroll = (index) => {
    let scrollTimer
    return () => {
      const containerElement = imageContainerRefs.current[index]?.current
      if (containerElement) {
        clearTimeout(scrollTimer)
        const shouldHide =
          containerElement.scrollWidth > containerElement.clientWidth
        setScrollStatus((prevStatus) => ({
          ...prevStatus,
          [index]: shouldHide,
        }))

        scrollTimer = setTimeout(() => {
          setScrollStatus((prevStatus) => ({ ...prevStatus, [index]: false }))
        }, 0)
      }
    }
  }

  useEffect(() => {
    imageContainerRefs.current.forEach((containerRef, index) => {
      const containerElement = containerRef.current
      if (containerElement) {
        containerElement.addEventListener('scroll', handleScroll(index))
      }
    })
    return () => {
      imageContainerRefs.current.forEach((containerRef) => {
        const containerElement = containerRef.current
        if (containerElement) {
          containerElement.removeEventListener('scroll', handleScroll)
        }
      })
    }
  }, [viewDay])

  useEffect(() => {
    dispatch(getViewDay({ day, userId }))
  }, [])

  const renderCard = (value, index) => {
    if (value.array === 'images') {
      if (!imageContainerRefs.current[index]) {
        imageContainerRefs.current[index] = React.createRef()
      }
      return (
        <div className={styles.images}>
          <img
            src={value.images ? value.images : ''}
            alt="photo"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSrcImages(value.images)
              setOpen(true)
            }}
          />
          <div className={styles.innerControl}>
            <div
              className={styles.controlImages}
              onClick={() => handleDownloadClick(value.images, index)}
            >
              <img
                src={down}
                alt="down"
              />
            </div>
            <div
              className={styles.controlImages}
              onClick={() => {
                setSrcImages(value.images)
                setOpen(true)
              }}
            >
              <img
                src={zoom}
                alt="zoom"
              />
            </div>
          </div>
          {scrollStatus[index] ? (
            <div
              className={styles.imageContainer}
              ref={imageContainerRefs.current[index]}
            >
              <img
                className={styles.scrollableImage}
                src={ruka}
                alt="scroll"
              />
            </div>
          ) : null}
        </div>
      )
    } else if (value.array === 'text') {
      return <div className={styles.text}>{parse(`${value.title}`)}</div>
    } else if (value.array === 'tabl') {
      return value.table.length ? (
        <table className={styles.tabl}>
          <tbody>
            <tr>
              {value.table[0].col.map((i, index) => (
                <th key={index}>{i.Header}</th>
              ))}
            </tr>
            {value.table[0].row.map((val, index) => (
              <tr key={index}>
                {Object.values(val).map((value, index) => (
                  <td key={index}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : null
    } else if (value.array === 'list') {
      return (
        <div className={styles.listInner}>
          {value.list.map((li, index) => (
            <ul key={index}>
              <li
                className={classNames(styles.listDecimal, {
                  [styles.list]: value.isDot,
                })}
              >
                {li}
              </li>
            </ul>
          ))}
        </div>
      )
    } else {
      return <div className={styles.title}>{value.title}</div>
    }
  }
  return (
    <div className={styles.container}>
      {!viewDay.length ? (
        <Loader />
      ) : (
        <div className={styles.inner}>
          <div className={styles.innerWrap}>
            <ProgressBar day={day} />
            <h1 className={styles.mumDay}>{`День ${day}`}</h1>
            {/* <div className={styles.exit}>
              <div className={styles.back}>
                <BiArrowBack />
                Назад
              </div>
            </div> */}
          </div>
          <div className={styles.textDay}>
            {viewDay.map((value, index) => (
              <div
                style={{ position: 'relative' }}
                key={index}
              >
                <div
                  ref={imageContainerRefs.current[index]}
                  key={index}
                  className={classNames(styles.innerContent, {
                    [styles.innerContentImages]: value.array === 'images',
                    [styles.innerContentTabl]: value.array === 'tabl',
                  })}
                >
                  {renderCard(value, index)}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        plugins={[Zoom, Fullscreen]}
        showArrow={false}
        slides={[
          {
            src: srcImages,
          },
        ]}
      />
    </div>
  )
}
