import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import './AdminPage.scss'

import { BlockDay } from '../../components/BlockDay/BlockDay'
import { BlockMain } from '../../components/BlockMain/BlockMain'
import { ModalLogin } from '../../components/ModalLogin/ModalLogin'
import Selected from '../../components/Selected'
import { adminData } from '../../data'

export default function AdminPage() {
  const registration = useSelector((state) => state.adminSlice.registration)
  const [selectAdmin, setSelectAdmin] = useState('')

  const handleChange = (value) => {
    setSelectAdmin(value)
  }

  useEffect(() => {
    setSelectAdmin('')
  }, [])

  return (
    <div className="container">
      {registration ? (
        <div className="innerContent">
          {!selectAdmin ? (
            <Selected
              data={adminData}
              handleChange={handleChange}
              value={'Выбрать админ панель'}
            />
          ) : (
            <>
              <BlockDay
                selectAdmin={selectAdmin}
                setSelectAdmin={setSelectAdmin}
              />
              <BlockMain selectAdmin={selectAdmin} />
            </>
          )}
        </div>
      ) : (
        <ModalLogin />
      )}
    </div>
  )
}
