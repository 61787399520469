import { create } from 'apisauce'

const baseUrl =
  window.location.origin === 'http://195.2.70.179:3000'
    ? 'http://195.2.70.179:5000/api'
    : '/api'

export const API = create({
  baseURL: baseUrl,
  withCredentials: true,
})

const saveDay = (data, token) => {
  const { dayNum, content } = data
  return API.post(
    '/save_day',
    { dayNum, content },
    {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    }
  )
}

const loadDay = (data) => {
  const { userId, day } = data
  return API.get(
    `/load_day?day=${day}&userId=${userId}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

const loadDayAdmin = (data, token) => {
  return API.get(
    `/load_day_admin?day=${data}`,
    {},
    {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    }
  )
}

const loadDiary = (data) => {
  const { id, day } = data
  return API.get(
    `/load_diary?day=${day}&userId=${id}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

const saveDiary = (data) => {
  const { dayNum, userId, content } = data
  return API.post(
    '/save_diary',
    { dayNum, userId, content },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

const saveTest = (data) => {
  const { num, userId, content } = data
  return API.post(
    '/save_test',
    { num, userId, content },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

const loadTest = (data) => {
  const { userId, num } = data
  return API.get(
    `/load_test`,
    { userId, num },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

const loginAdmin = (data) => {
  const { login, password } = data
  return API.post(
    '/login_admin',
    { login, password },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

const saveDiaryAdmin = (data, token) => {
  const { dayNum, content } = data
  return API.post(
    '/save_diary_admin',
    { dayNum, content },
    {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    }
  )
}

const loadDiaryAdmin = (data, token) => {
  const { day } = data
  return API.get(
    `/load_diary_admin?day=${day}`,
    {},
    {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    }
  )
}

const getKpi1Admin = (token) => {
  return API.get(
    `/kpi/1`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    }
  )
}

const getKpi2Admin = (token) => {
  return API.get(
    `/kpi/2`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    }
  )
}

const getKpi3Admin = (token) => {
  return API.get(
    `/kpi/3`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    }
  )
}

const getKpi4Admin = (token) => {
  return API.get(
    `/kpi/4`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    }
  )
}

const getKpi5Admin = (token) => {
  return API.get(
    `/kpi/5`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    }
  )
}
const getKpi6Admin = (token) => {
  return API.get(
    `/kpi/6`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    }
  )
}

export default {
  loadDayAdmin,
  saveDay,
  loadDay,
  loadDiary,
  saveDiary,
  saveTest,
  loadTest,
  loginAdmin,
  saveDiaryAdmin,
  loadDiaryAdmin,
  getKpi1Admin,
  getKpi2Admin,
  getKpi3Admin,
  getKpi4Admin,
  getKpi5Admin,
  getKpi6Admin,
}
