// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListContent_titleDiv__OI5Sy {
  list-style: inside;
  padding-left: 0rem;
}

.ListContent_titleDivDecimal__KheNv {
  list-style: decimal;
  padding-left: 1rem;
}

.ListContent_innerIcon__wf9-2 {
  position: absolute;
  top: 17px;
  left: 20px;
  cursor: pointer;
}

.ListContent_wrapIn__uqMsM {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 8px;
}

.ListContent_input__ZROq4 {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 100%;
  outline: none;
}

.ListContent_innerSave__w0v5v {
  display: flex;
  justify-content: flex-end;
}

.ListContent_save__JbxuE {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  border-radius: 5px;
  background: #d9d9d9;
  padding: 4px 6px;
  cursor: pointer;
}
.ListContent_save__JbxuE:hover {
  background-color: #ffbdbd;
  transition: all 0.5s;
}

.ListContent_list__T8\\+NE {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ListContent_icons__SDwgx {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ListContent_inp__pVqZF {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ListContent/ListContent.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,yBAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;AACF;;AACA;EACE,uBAAA;EAAA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AAEF;AADE;EACE,yBAAA;EACA,oBAAA;AAGJ;;AACA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAEF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAEF;;AAAA;EACE,aAAA;AAGF","sourcesContent":[".titleDiv {\n  list-style: inside;\n  padding-left: 0rem;\n}\n\n.titleDivDecimal {\n  list-style: decimal;\n  padding-left: 1rem;\n}\n\n.innerIcon {\n  position: absolute;\n  top: 17px;\n  left: 20px;\n  cursor: pointer;\n}\n\n.wrapIn {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 10px;\n  margin-bottom: 10px;\n  margin-top: 8px;\n}\n\n.input {\n  border: 1px solid #d9d9d9;\n  border-radius: 5px;\n  width: 100%;\n  outline: none;\n}\n\n.innerSave {\n  display: flex;\n  justify-content: flex-end;\n}\n.save {\n  width: fit-content;\n  display: flex;\n  border-radius: 5px;\n  background: #d9d9d9;\n  padding: 4px 6px;\n  cursor: pointer;\n  &:hover {\n    background-color: #ffbdbd;\n    transition: all 0.5s;\n  }\n}\n\n.list {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.icons {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n.inp {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleDiv": `ListContent_titleDiv__OI5Sy`,
	"titleDivDecimal": `ListContent_titleDivDecimal__KheNv`,
	"innerIcon": `ListContent_innerIcon__wf9-2`,
	"wrapIn": `ListContent_wrapIn__uqMsM`,
	"input": `ListContent_input__ZROq4`,
	"innerSave": `ListContent_innerSave__w0v5v`,
	"save": `ListContent_save__JbxuE`,
	"list": `ListContent_list__T8+NE`,
	"icons": `ListContent_icons__SDwgx`,
	"inp": `ListContent_inp__pVqZF`
};
export default ___CSS_LOADER_EXPORT___;
