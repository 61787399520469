// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_wrap__FKFye {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Loader_ldsRipple__4SkVn {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.Loader_ldsRipple__4SkVn div {
  position: absolute;
  border: 4px solid #e85a4f;
  opacity: 1;
  border-radius: 50%;
  animation: Loader_lds-ripple__8-1r0 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.Loader_ldsRipple__4SkVn div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes Loader_lds-ripple__8-1r0 {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AACA;EACE,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AAEF;;AAAA;EACE,kBAAA;EACA,yBAAA;EACA,UAAA;EACA,kBAAA;EACA,4EAAA;AAGF;;AADA;EACE,sBAAA;AAIF;;AAFA;EACE;IACE,SAAA;IACA,UAAA;IACA,QAAA;IACA,SAAA;IACA,UAAA;EAKF;EAHA;IACE,SAAA;IACA,UAAA;IACA,QAAA;IACA,SAAA;IACA,UAAA;EAKF;EAHA;IACE,SAAA;IACA,UAAA;IACA,QAAA;IACA,SAAA;IACA,UAAA;EAKF;EAHA;IACE,QAAA;IACA,SAAA;IACA,WAAA;IACA,YAAA;IACA,UAAA;EAKF;AACF","sourcesContent":[".wrap {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n.ldsRipple {\n  display: inline-block;\n  position: relative;\n  width: 80px;\n  height: 80px;\n}\n.ldsRipple div {\n  position: absolute;\n  border: 4px solid #e85a4f;\n  opacity: 1;\n  border-radius: 50%;\n  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;\n}\n.ldsRipple div:nth-child(2) {\n  animation-delay: -0.5s;\n}\n@keyframes lds-ripple {\n  0% {\n    top: 36px;\n    left: 36px;\n    width: 0;\n    height: 0;\n    opacity: 0;\n  }\n  4.9% {\n    top: 36px;\n    left: 36px;\n    width: 0;\n    height: 0;\n    opacity: 0;\n  }\n  5% {\n    top: 36px;\n    left: 36px;\n    width: 0;\n    height: 0;\n    opacity: 1;\n  }\n  100% {\n    top: 0px;\n    left: 0px;\n    width: 72px;\n    height: 72px;\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Loader_wrap__FKFye`,
	"ldsRipple": `Loader_ldsRipple__4SkVn`,
	"lds-ripple": `Loader_lds-ripple__8-1r0`
};
export default ___CSS_LOADER_EXPORT___;
