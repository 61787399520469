import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import RadioInput from '../RadioInput/RadioInput'
import { getSaveTest, getTestB } from '../../redux/Client/clientSlice'
import styles from './TestB.module.scss'
import testImg from '../../assets/img/test.jpg'
import { BiArrowBack } from 'react-icons/bi'
import classNames from 'classnames'

export const TestB = () => {
  const dispatch = useDispatch()
  const testRender = useSelector((state) => state.clientSlice.testB)
  const [test, setTest] = useState()
  const [inputValue, setInputValue] = useState(0)
  const { id, day } = useParams()
  const navigate = useNavigate()

  const saveTestHandler = () => {
    if (inputValue) {
      dispatch(
        getSaveTest({
          num: 31,
          userId: id,
          content: { test, inputValue: inputValue },
        })
      )
    } else return
  }

  useEffect(() => {
    dispatch(getTestB({ num: 31, userId: id }))
  }, [])

  useEffect(() => {
    setTest(testRender?.test)
  }, [testRender?.test])

  useEffect(() => {
    setInputValue(test?.filter((obj) => obj.answers === 'Да').length)
  }, [test])
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div
          className={styles.exit}
          onClick={() => navigate(`/diary-journal?day=${day}&userId=${id}`)}
        >
          <div className={styles.back}>
            <BiArrowBack />
            Назад
          </div>
        </div>
        <img
          className={styles.img}
          src={testImg}
          alt=""
        />
        <div className={styles.title}>
          Поздравляю с финальным днем нашего курса🤩 <br />
          Давай зафиксируем точку Б и пройдем тест, который мы проходили в самом
          начале 👍🏼
        </div>
        {testRender?.test
          ? testRender?.test.map((value) => (
              <div
                key={value.id}
                className={styles.wrapQ}
              >
                <div className={styles.tetx}>{value.question}</div>
                <RadioInput
                  disabled={!!value.answers}
                  id={value.id}
                  valueArr={value.answers}
                  setTest={setTest}
                  test={test}
                />
              </div>
            ))
          : []}
        <div className={styles.text}>
          Твой резудьтат теста, в котором подсчитано сколько раз было ДА:
        </div>
        <div className={styles.inp}>{inputValue}</div>
        <div className={styles.text}>
          А теперь сравни этот результат с результатом теста А, он у нас был в
          самом начале.
        </div>
        <div className={styles.text}>
          Если число стало меньше, я оооооочень за тебя рад 🤩 Ты победитель! Ты
          мой космос! 🚀 Это значит, что ты серьезно относишься к своему
          здоровью и мышлению, и нашему курсу, старался выполнять задания и
          получил заслуженную награду в виде новых полезных навыков мышления🤩
          Не останавливайся на достигнутом, закрепляй приобретенное!
        </div>
        <div className={styles.text}>
          Если цифра не изменилась, то ничего страшного🙌🏼 Это только начало!
          Попробуй еще раз, неторопясь, пройтись по всем заданиям, которые
          остались в нашем чате. Повтори их и постепенно выполняй, формируя
          полезную привычку🤩
        </div>
        <div className={styles.text}>
          Если цифра стала больше 😱😱😱 то я буду горько плакать от сожаления,
          что так получилось, и надеяться, что такой результат получился лишь
          потому, что у тебя сейчас непростой период в жизни. В такие периоды
          действительно сложно познавать новое и формировать новые полезные
          навыки. Возможно, сейчас для тебя важнее другое. А я желаю тебе
          восстановится, набраться сил и снова пройти задания, когда у тебя
          будет появится более лучший настрой и силы 🤩 А еще лучше, разбирать
          все свои сложности в наших дневниках - быстрее полегчает!🚀
        </div>
        {!testRender?.inputValue && (
          <div
            className={classNames(styles.btn, {
              [styles.btnDisable]: !inputValue,
            })}
            onClick={saveTestHandler}
          >
            Сохранить результат
          </div>
        )}
      </div>
    </div>
  )
}
