// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Editor_text__hPdUS {
  word-wrap: break-word;
}

.Editor_innerSave__wN31a {
  display: flex;
  justify-content: flex-end;
}

.Editor_save__OujtE {
  margin-top: 8px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  border-radius: 5px;
  background: #d9d9d9;
  padding: 4px 6px;
  cursor: pointer;
}
.Editor_save__OujtE:hover {
  background-color: #ffbdbd;
  transition: all 0.5s;
}`, "",{"version":3,"sources":["webpack://./src/components/Editor/Editor.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AACA;EACE,aAAA;EACA,yBAAA;AAEF;;AAAA;EACE,eAAA;EACA,uBAAA;EAAA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AAGF;AAFE;EACE,yBAAA;EACA,oBAAA;AAIJ","sourcesContent":[".text {\n  word-wrap: break-word;\n}\n.innerSave {\n  display: flex;\n  justify-content: flex-end;\n}\n.save {\n  margin-top: 8px;\n  width: fit-content;\n  display: flex;\n  border-radius: 5px;\n  background: #d9d9d9;\n  padding: 4px 6px;\n  cursor: pointer;\n  &:hover {\n    background-color: #ffbdbd;\n    transition: all 0.5s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `Editor_text__hPdUS`,
	"innerSave": `Editor_innerSave__wN31a`,
	"save": `Editor_save__OujtE`
};
export default ___CSS_LOADER_EXPORT___;
