import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoginAdmin } from '../../redux/Admin/adminSlice'
import styles from './ModalLogin.module.scss'
import Loader from '../Loader'

export const ModalLogin = () => {
  const dispatch = useDispatch()
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const statusLogin = useSelector((state) => state.adminSlice.statusLogin)
  return (
    <div className={styles.wrap}>
      {statusLogin === 'pending' ? (
        <Loader />
      ) : (
        <div className={styles.inner}>
          <div style={{ width: '100%' }}>
            <div style={{ marginBottom: '5px' }}>Логин</div>
            <input
              value={login}
              className={styles.input}
              type="text"
              onChange={(e) => setLogin(e.target.value)}
            />
          </div>
          <div style={{ width: '100%' }}>
            <div style={{ marginBottom: '5px' }}>Пароль</div>
            <input
              value={password}
              className={styles.input}
              type="text"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            className={styles.button}
            onClick={() => {
              dispatch(getLoginAdmin({ login, password }))
              setLogin('')
              setPassword('')
            }}
          >
            Войти
          </button>
        </div>
      )}
    </div>
  )
}
