// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_btn__rk-ay {
  padding: 17px;
  border-radius: 30px;
  min-width: 201px;
  background-color: #d9d9d9;
  color: #000;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.4px;
  transition: all 0.5s;
  border: none;
}
.Button_btn__rk-ay:hover {
  background-color: #ffbdbd;
  transition: all 0.5s;
}

.Button_active__nVfQq {
  background-color: #ffbdbd;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,oBAAA;EACA,YAAA;AACF;AAAE;EACE,yBAAA;EACA,oBAAA;AAEJ;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".btn {\n  padding: 17px;\n  border-radius: 30px;\n  min-width: 201px;\n  background-color: #d9d9d9;\n  color: #000;\n  text-align: center;\n  font-size: 17px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.4px;\n  transition: all 0.5s;\n  border: none;\n  &:hover {\n    background-color: #ffbdbd;\n    transition: all 0.5s;\n  }\n}\n\n.active {\n  background-color: #ffbdbd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `Button_btn__rk-ay`,
	"active": `Button_active__nVfQq`
};
export default ___CSS_LOADER_EXPORT___;
