import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { getSaveDiary } from '../../redux/Client/clientSlice'
import { useTable } from 'react-table'
import styled from 'styled-components'
import styles from './TablClientNew.module.scss'
import { FcDeleteRow } from 'react-icons/fc'
import classNames from 'classnames'

const StyledTable = styled.table`
  width: 100%;
  margin-top: 10px;
  border-radius: 30px;
`

const Textarea = styled.textarea`
  padding: 8px;
  border: 1px solid #c4c4c4;
  width: 100%;
  height: inherit;
  outline: none;
  resize: none;
`

const Th = styled.th`
  background-color: transparent;
  padding: 10px 25px;
  border-radius: 5px;
  border: 1px solid #000;
  text-align: center;
  position: relative;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const Td = styled.td`
  border: 1px solid #000;
  padding: 10px;
  height: 135px;
  min-width: 180px;
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: top;
  position: relative;
`

const TablNew = (props) => {
  const { arr, tablCard, dayNum, userId, array } = props
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data })
  const [editCell, setEditCell] = useState(null)

  const handleDeleteRow = (rowIndex) => {
    const updatedData = [...data]
    updatedData.splice(rowIndex, 1)
    setData(updatedData)
  }

  const handleCellEdit = (rowIndex, columnId, value) => {
    const updatedData = [...data]
    updatedData[rowIndex] = {
      ...updatedData[rowIndex],
      [columnId]: value,
    }
    setData(updatedData)
  }

  const addRow = () => {
    setData([...data, {}])
  }

  const saveData = () => {
    dispatch(
      getSaveDiary({
        dayNum,
        userId,
        content: arr.map((value) => {
          return { ...value, table: [{ row: data, col: columns }], edit: false }
        }),
      })
    )
  }

  useEffect(() => {
    if (tablCard.length) {
      setData(tablCard[0].row)
      setColumns(tablCard[0].col)
    } else {
      setData([])
      setColumns([
        { Header: 'Событие', accessor: 'event' },
        { Header: 'Мысли', accessor: 'thoughts' },
      ])
    }
  }, [tablCard])

  return (
    <div>
      <div className={styles.contTable}>
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, groupIndex) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={groupIndex}
              >
                {headerGroup.headers.map((column, columnIndex) => (
                  <Th {...column.getHeaderProps()}>
                    <div>
                      {column.render('Header')}
                      <div className={styles.deleteColl}></div>
                    </div>
                  </Th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  key={rowIndex}
                >
                  {row.cells.map((cell, cellIndex) => (
                    <Td
                      {...cell.getCellProps()}
                      onClick={() =>
                        setEditCell({
                          rowIndex,
                          columnId: columns[cellIndex].accessor,
                        })
                      }
                      key={cellIndex}
                    >
                      <div
                        style={{
                          // position: 'relative',
                          height: 'inherit',
                          width: '100%',
                          overflow: 'auto',
                        }}
                      >
                        {editCell &&
                        editCell.rowIndex === rowIndex &&
                        editCell.columnId === columns[cellIndex].accessor ? (
                          <Textarea
                            value={cell.value}
                            onBlur={() => setEditCell(null)}
                            onChange={(e) =>
                              handleCellEdit(
                                rowIndex,
                                columns[cellIndex].accessor,
                                e.target.value
                              )
                            }
                          ></Textarea>
                        ) : (
                          // <Input
                          //   type="text"
                          //   value={cell.value}
                          //   onChange={(e) =>
                          //     handleCellEdit(
                          //       rowIndex,
                          //       columns[cellIndex].accessor,
                          //       e.target.value
                          //     )
                          //   }
                          //   onBlur={() => setEditCell(null)}
                          // />
                          cell.render('Cell')
                        )}
                        <button
                          onClick={() => handleDeleteRow(rowIndex)}
                          type="primary"
                          className={classNames(styles.delRow, {
                            [styles.customButtonClass]: cellIndex === 0,
                          })}
                        >
                          <span>x</span> Удалить строку
                        </button>
                      </div>
                    </Td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </StyledTable>
        <button
          onClick={addRow}
          type="primary"
          className={classNames(styles.btn, {
            [styles.btnStart]: !rows.length,
          })}
        >
          <span>+</span> Добавить строку
        </button>
      </div>
      <div className={styles.innerSave}>
        <div
          onClick={saveData}
          className={classNames(styles.save, {
            [styles.saveTablClient]: array === 'tabl',
          })}
        >
          Сохранить
        </div>
      </div>
    </div>
  )
}
export default TablNew
