import React, { useState, useEffect } from 'react'
import styles from './ProgressBar.module.scss'

const ProgressBar = (props) => {
  const { day } = props
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const totalDays = 30
    const currentDay = day
    const newProgress = (currentDay / totalDays) * 100
    setProgress(newProgress)
  }, [])

  return (
    <>
      <div className={styles.dayNumContener}>
        <div>0</div>
        <div>{day}</div>
      </div>
      <div className={styles.progressContainer}>
        <div
          className={styles.progressBar}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </>
  )
}

export default ProgressBar
