// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_wrap__P0Cyp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  width: 392px;
  height: 163px;
  border-radius: 30px;
  background-color: rgb(64, 73, 75);
  padding: 17px 18px 22px 24px;
  position: fixed;
  left: 60%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.Modal_showModal__OlWjc {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}

.Modal_item__c0PTX {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 112px;
  height: 59px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  transition: all 0.5s;
}
.Modal_item__c0PTX:hover {
  background-color: #ffbdbd;
  transition: all 0.5s;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,mBAAA;EACA,2BAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,iCAAA;EACA,4BAAA;EACA,eAAA;EACA,SAAA;EACA,QAAA;EACA,UAAA;EACA,kBAAA;EACA,gCAAA;AACF;;AAEA;EACE,UAAA;EACA,mBAAA;EACA,gCAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,oCAAA;EACA,kBAAA;EACA,oBAAA;AACF;AACE;EACE,yBAAA;EACA,oBAAA;AACJ","sourcesContent":[".wrap {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 7px;\n  width: 392px;\n  height: 163px;\n  border-radius: 30px;\n  background-color: rgba(64, 73, 75, 1);\n  padding: 17px 18px 22px 24px;\n  position: fixed;\n  left: 60%;\n  top: 50%;\n  opacity: 0;\n  visibility: hidden;\n  transition: all 0.5s ease-in-out;\n}\n\n.showModal {\n  opacity: 1;\n  visibility: visible;\n  transition: all 0.5s ease-in-out;\n}\n\n.item {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  width: 112px;\n  height: 59px;\n  border-radius: 10px;\n  background-color: rgba(255, 255, 255, 1);\n  text-align: center;\n  transition: all 0.5s;\n\n  &:hover {\n    background-color: #ffbdbd;\n    transition: all 0.5s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Modal_wrap__P0Cyp`,
	"showModal": `Modal_showModal__OlWjc`,
	"item": `Modal_item__c0PTX`
};
export default ___CSS_LOADER_EXPORT___;
