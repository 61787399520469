import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getViewDiary } from '../../redux/Client/clientSlice'
import { useNavigate, useParams } from 'react-router-dom'
import parse from 'html-react-parser'
import classNames from 'classnames'

import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import Lightbox from 'yet-another-react-lightbox'

import Loader from '../../components/Loader'
import TablClientNew from '../../components/TablClientNew/TablClientNew'

import { BiArrowBack } from 'react-icons/bi'
import ProgressBar from '../../components/ProgressBar/ProgressBar'
import zoom from '../../assets/img/zoom.svg'
import down from '../../assets/img/dow.svg'
import ruka from '../../assets/img/ruka.png'
import styles from './ViewDiary.module.scss'

export const ViewDiary = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const viewDiary = useSelector((state) => state.clientSlice.viewDiary)
  const loadingTabl = useSelector((state) => state.clientSlice.loadingTabl)
  const imageContainerRefs = useRef([])
  const [open, setOpen] = useState(false)
  const [srcImages, setSrcImages] = useState('')
  const [scrollStatus, setScrollStatus] = useState({})
  const { day, id, sd } = useParams()

  const handleDownloadClick = (url, index) => {
    const link = document.createElement('a')
    link.href = url
    link.download = `images${index}.png`
    link.click()
  }

  const hasHorizontalScroll = (element) => {
    return element.scrollWidth > element.clientWidth
  }

  useEffect(() => {
    const updatedScrollStatus = {}
    imageContainerRefs.current.forEach((containerRef, index) => {
      const containerElement = containerRef.current
      if (containerElement) {
        updatedScrollStatus[index] = hasHorizontalScroll(containerElement)
      }
    })
    setScrollStatus(updatedScrollStatus)
  }, [viewDiary])

  const handleScroll = (index) => {
    let scrollTimer
    return () => {
      const containerElement = imageContainerRefs.current[index]?.current
      if (containerElement) {
        clearTimeout(scrollTimer)
        const shouldHide =
          containerElement.scrollWidth > containerElement.clientWidth
        setScrollStatus((prevStatus) => ({
          ...prevStatus,
          [index]: shouldHide,
        }))

        scrollTimer = setTimeout(() => {
          setScrollStatus((prevStatus) => ({ ...prevStatus, [index]: false }))
        }, 0)
      }
    }
  }

  useEffect(() => {
    imageContainerRefs.current.forEach((containerRef, index) => {
      const containerElement = containerRef.current
      if (containerElement) {
        containerElement.addEventListener('scroll', handleScroll(index))
      }
    })
    return () => {
      imageContainerRefs.current.forEach((containerRef) => {
        const containerElement = containerRef.current
        if (containerElement) {
          containerElement.removeEventListener('scroll', handleScroll)
        }
      })
    }
  }, [viewDiary])

  useEffect(() => {
    dispatch(getViewDiary({ day, id }))
  }, [])
  const renderCard = (value, index) => {
    if (value.array === 'images') {
      if (!imageContainerRefs.current[index]) {
        imageContainerRefs.current[index] = React.createRef()
      }
      return (
        <div className={styles.images}>
          <img
            src={value.images ? value.images : ''}
            alt="photo"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSrcImages(value.images)
              setOpen(true)
            }}
          />
          <div className={styles.innerControl}>
            <div
              className={styles.controlImages}
              onClick={() => handleDownloadClick(value.images, index)}
            >
              <img
                src={down}
                alt="down"
              />
            </div>
            <div
              className={styles.controlImages}
              onClick={() => {
                setSrcImages(value.images)
                setOpen(true)
              }}
            >
              <img
                src={zoom}
                alt="zoom"
              />
            </div>
          </div>
          {scrollStatus[index] ? (
            <div
              className={styles.imageContainer}
              ref={imageContainerRefs.current[index]}
            >
              <img
                className={styles.scrollableImage}
                src={ruka}
                alt="scroll"
              />
            </div>
          ) : null}
        </div>
      )
    } else if (value.array === 'text') {
      return <div className={styles.text}>{parse(`${value.title}`)}</div>
    } else if (value.array === 'tabl') {
      return loadingTabl === 'pending' ? (
        <Loader className={styles.loader} />
      ) : (
        <TablClientNew
          id={value.id}
          editPanel={value.edit}
          arr={viewDiary}
          tablCard={value.table}
          userId={id}
          dayNum={day}
          array={value.array}
        />
      )
    } else if (value.array === 'list') {
      return (
        <div className={styles.listInner}>
          {value.list.map((li, index) => (
            <ul key={index}>
              <li
                className={classNames(styles.listDecimal, {
                  [styles.list]: value.isDot,
                })}
              >
                {li}
              </li>
            </ul>
          ))}
        </div>
      )
    } else {
      return <div className={styles.title}>{value.title}</div>
    }
  }
  return (
    <div className={styles.container}>
      {!viewDiary.length ? (
        <Loader />
      ) : (
        <div className={styles.inner}>
          <div className={styles.innerWrap}>
            <ProgressBar day={day} />
            <h1 className={styles.mumDay}>{`День ${day}`}</h1>
            <div
              className={styles.exit}
              onClick={() => navigate(`/diary-journal?day=${sd}&userId=${id}`)}
            >
              <div className={styles.back}>
                <BiArrowBack />
                Назад
              </div>
            </div>
          </div>
          <div className={styles.textDay}>
            {viewDiary.map((value, index) => (
              <div
                style={{ position: 'relative' }}
                key={index}
              >
                <div
                  ref={imageContainerRefs.current[index]}
                  className={classNames(styles.innerContent, {
                    [styles.innerContentTabl]: value.array === 'tabl',
                    [styles.innerContentImages]: value.array === 'images',
                    [styles.block]:
                      value.list.length ||
                      value.title ||
                      value.array === 'tabl' ||
                      value.images,
                  })}
                  style={{ position: 'relative' }}
                >
                  {renderCard(value, index)}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        plugins={[Zoom, Fullscreen]}
        showArrow={false}
        slides={[
          {
            src: srcImages,
          },
        ]}
      />
    </div>
  )
}
