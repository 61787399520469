// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlockMain_wrap__xMKeg {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: 484px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 30px;
  background: #ffbdbd;
  padding: 24px 49px 41px 45px;
}

.BlockMain_btn__Xlh9v {
  width: 100%;
  height: 40px;
  padding: 10px;
  background-color: #a8a8a8;
  text-align: center;
  border-radius: 20px;
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.6s;
}
.BlockMain_btn__Xlh9v:hover {
  background-color: #d9d9d9;
  transition: all 0.6s;
}

.BlockMain_day__TQGKS {
  border-radius: 30px;
  background: #fff;
  width: 100%;
  padding: 15px 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/BlockMain/BlockMain.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,YAAA;EACA,wBAAA;EAAA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,4BAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,oBAAA;AACF;AAAE;EACE,yBAAA;EACA,oBAAA;AAEJ;;AAEA;EACE,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;AACF","sourcesContent":[".wrap {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  gap: 9px;\n  width: 484px;\n  height: fit-content;\n  border-radius: 30px;\n  background: #ffbdbd;\n  padding: 24px 49px 41px 45px;\n}\n\n.btn {\n  width: 100%;\n  height: 40px;\n  padding: 10px;\n  background-color: #a8a8a8;\n  text-align: center;\n  border-radius: 20px;\n  margin-top: 10px;\n  cursor: pointer;\n  transition: all 0.6s;\n  &:hover {\n    background-color: #d9d9d9;\n    transition: all 0.6s;\n  }\n}\n\n.day {\n  border-radius: 30px;\n  background: #fff;\n  width: 100%;\n  padding: 15px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `BlockMain_wrap__xMKeg`,
	"btn": `BlockMain_btn__Xlh9v`,
	"day": `BlockMain_day__TQGKS`
};
export default ___CSS_LOADER_EXPORT___;
