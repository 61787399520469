import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

const DoughnutDiagram = (props) => {
  const { data } = props
  return (
    <Doughnut
      data={data}
      options={{
        aspectRatio: 2,
      }}
    />
  )
}

export default DoughnutDiagram
