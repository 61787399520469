import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import adminSlice from './Admin/adminSlice'
import clientSlice from './Client/clientSlice'
import { rootSaga } from './Sagas/rootSaga'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    adminSlice,
    clientSlice,
  },
  middleware: [sagaMiddleware],
})

sagaMiddleware.run(rootSaga)
