// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RadioInput_input__zlYso {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 122.727% */
}

@media (max-width: 768px) {
  .RadioInput_input__zlYso {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .RadioInput_input__zlYso {
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/RadioInput/RadioInput.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;AACF;;AAEA;EACE;IACE,eAAA;EACF;AACF;AAEA;EACE;IACE,eAAA;EAAF;AACF","sourcesContent":[".input {\n  color: #000;\n  font-size: 22px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 27px; /* 122.727% */\n}\n\n@media (max-width: 768px) {\n  .input {\n    font-size: 16px;\n  }\n}\n\n@media (max-width: 400px) {\n  .input {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `RadioInput_input__zlYso`
};
export default ___CSS_LOADER_EXPORT___;
