import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setArr } from '../../redux/Admin/adminSlice'

import styles from './TitleContent.module.scss'

export const TitleContent = (props) => {
  const { edit, arr, id, titleCard } = props
  const [title, setTitle] = useState(titleCard)
  const dispatch = useDispatch()
  const saveData = () => {
    dispatch(
      setArr(
        arr.map((value) =>
          value.id === id
            ? { ...value, title: title, edit: false }
            : { ...value }
        )
      )
    )
  }
  const onChangeKeyDown = (event) => {
    if (event.key === 'Enter') {
      dispatch(
        setArr(
          arr.map((value) =>
            value.id === id
              ? { ...value, title: title, edit: false }
              : { ...value }
          )
        )
      )
    }
  }
  useEffect(() => {
    setTitle(titleCard)
  }, [edit])
  return (
    <div>
      {edit ? (
        <>
          <input
            onKeyDown={onChangeKeyDown}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className={styles.title}
            disabled={!edit}
            placeholder="Введите текст"
          />
          <div className={styles.innerSave}>
            <div
              onClick={saveData}
              className={styles.save}
            >
              Сохранить
            </div>
          </div>
        </>
      ) : (
        <div className={styles.titleDiv}>{titleCard}</div>
      )}
    </div>
  )
}
