import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  setViewDay,
  getViewDay,
  getViewDiary,
  setViewDiary,
  getSaveDiary,
  getSaveTest,
  setTestA,
  getTestA,
  setTestB,
  getTestB,
  setloadingTabl,
} from '../Client/clientSlice'
import { setArr } from '../Admin/adminSlice'
import { toast } from 'react-toastify'
import API from '../utils/API'
import { dataArr } from '../../data'

function* viewDayWorker(action) {
  const { ok, data } = yield call(API.loadDay, action.payload)
  if (ok) {
    if (data === 'Day not found') {
      toast.error('День не найден!')
      yield put(setViewDay([]))
    } else if (data === 'userId is required') {
        toast.error('Юзер не передан в качетсве параметра!')
        yield put(setViewDay([]))
    } else if (data === 'User not found') {
        toast.error('Юзер не найден!')
        yield put(setViewDay([]))
    } else if (data === 'User does not have access to day') {
        toast.error('У юзера нет доступа к этому дню!')
        yield put(setViewDay([]))
    } else {
      yield put(setViewDay(data))
      toast.success('Информация успешно загружена!')
    }
  } else {
    toast.error('Не удалось загрузить информацию. Попробуйте еще раз!')
  }
}

function* loadDiaryWorker(action) {
  yield put(setloadingTabl('pending'))
  const { data, ok } = yield call(API.loadDiary, action.payload)
  if (data && ok) {
    if (data === 'Day not found') {
      yield put(setloadingTabl('fullfilled'))
      yield put(setViewDiary(dataArr))
      yield put(setArr(dataArr))
    } else {
      yield put(setloadingTabl('fullfilled'))
      yield put(setViewDiary(data))
      yield put(setArr(data))
      toast.success('Информация успешно загружена!')
    }
  } else {
    yield put(setloadingTabl('regected'))
    toast.error('Не удалось загрузить информацию. Попробуйте еще раз!')
  }
}

function* saveDiaryWorker(action) {
  const { userId } = action.payload
  const { data, ok } = yield call(API.saveDiary, action.payload)
  if (ok) {
    toast.success('Информация успешна сохранена!')
  } else {
    if (data === 'User does not exist') {
      toast.error(`Пользователя с id ${userId} не существует!`)
    } else {
      toast.error('Не удалось сохранить информацию. Попробуйте еще раз!')
    }
  }
}

function* saveTestWorker(action) {
  const { ok } = yield call(API.saveTest, action.payload)
  if (ok) {
    toast.success('Информация успешна сохранена!')
  } else {
    toast.error('Не удалось сохранить информацию. Попробуйте еще раз!')
  }
}

function* getTestAWorker(action) {
  const { ok, data } = yield call(API.loadTest, action.payload)
  if (ok) {
    if (data !== 'Test not found') {
      yield put(setTestA(data))
      toast.success('Информация успешно загружена!')
    }
  } else {
    toast.error('Не удалось загрузить информацию. Попробуйте еще раз!')
  }
}

function* getTestBWorker(action) {
  const { ok, data } = yield call(API.loadTest, action.payload)
  if (ok) {
    if (data !== 'Test not found') {
      yield put(setTestB(data))
      toast.success('Информация успешно загружена!')
    }
  } else {
    toast.error('Не удалось загрузить информацию. Попробуйте еще раз!')
  }
}

export default function* userSaga() {
  yield all([takeLatest(getViewDay, viewDayWorker)])
  yield all([takeLatest(getViewDiary, loadDiaryWorker)])
  yield all([takeLatest(getSaveDiary, saveDiaryWorker)])
  yield all([takeLatest(getSaveTest, saveTestWorker)])
  yield all([takeLatest(getTestA, getTestAWorker)])
  yield all([takeLatest(getTestB, getTestBWorker)])
}

// [
//   {
//     title:
//       'Вспомни маленькое событие-триггер, которое произошло сегодня, а так же то, что именно ты подумал в момент этого события, свои мысли, и запиши в табличку:',
//     id: uniqid(),
//     edit: false,
//     images: '',
//     table: [],
//     list: [],
//     array: 'title',
//     isDot: true,
//   },
//   {
//     title: '',
//     table: [],
//     id: uniqid(),
//     edit: false,
//     images: '',
//     list: [],
//     array: 'tabl',
//     isDot: true,
//   },
// ]
