import React, { useState } from 'react'

import { FaListOl, FaListUl } from 'react-icons/fa'
import { AiOutlineDelete } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { setArr } from '../../redux/Admin/adminSlice'
import styles from './ListContent.module.scss'
import classNames from 'classnames'

export const ListContent = (props) => {
  const dispatch = useDispatch()
  const { edit, id, listCard, arr, isDote } = props
  // const [isDot, setIsDot] = useState(isDote)
  const [valueInput, setValueInput] = useState('')
  const [editMode, setEditMode] = useState(false)
  const [editInput, setEditInput] = useState('')

  const onChangeKeyDown = (event) => {
    if (valueInput) {
      if (event.key === 'Enter') {
        dispatch(
          setArr(
            arr.map((value) =>
              value.id === id
                ? {
                    ...value,
                    list: [...value.list, valueInput],
                    edit: false,
                    isDote: isDote,
                  }
                : { ...value }
            )
          )
        )
        setValueInput('')
        setEditMode(false)
      } else return
    }
  }
  const saveData = () => {
    if (valueInput) {
      dispatch(
        setArr(
          arr.map((value) =>
            value.id === id
              ? {
                  ...value,
                  list: [...value.list, valueInput],
                  edit: false,
                  isDote: isDote,
                }
              : { ...value }
          )
        )
      )
      setValueInput('')
      setEditMode(false)
    } else return
  }

  const deleteListHandler = (idr) => {
    dispatch(
      setArr(
        arr.map((value) =>
          value.id === id
            ? {
                ...value,
                list: value.list.filter((value) => value !== idr),
                edit: false,
                isDote: isDote,
              }
            : { ...value }
        )
      )
    )
  }

  const checkIsDote = () => {
    dispatch(
      setArr(
        arr.map((value) =>
          value.id === id
            ? {
                ...value,
                isDot: !value.isDot,
              }
            : { ...value }
        )
      )
    )
  }

  return (
    <div>
      {edit ? (
        <>
          <div
            className={styles.innerIcon}
            onClick={checkIsDote}
          >
            {isDote ? <FaListUl /> : <FaListOl />}
          </div>
          <div className={styles.wrapIn}>
            <input
              value={valueInput}
              onChange={(e) => setValueInput(e.target.value)}
              className={styles.input}
              disabled={!edit}
              onKeyDown={onChangeKeyDown}
            />
            <div className={styles.innerSave}>
              <div
                onClick={saveData}
                className={styles.save}
              >
                Добавить
              </div>
            </div>
          </div>
          <ul
            className={classNames(styles.titleDiv, {
              [styles.titleDivDecimal]: !isDote,
            })}
          >
            {listCard.map((value, index) => (
              <div
                key={index}
                className={styles.list}
              >
                {editMode ? (
                  <input
                    className={styles.inp}
                    value={editInput}
                    onChange={(e) => setEditInput(e.target.value)}
                  />
                ) : (
                  <li>{value}</li>
                )}
                <div className={styles.icons}>
                  <div onClick={() => deleteListHandler(value)}>
                    <AiOutlineDelete />
                  </div>
                </div>
              </div>
            ))}
          </ul>
        </>
      ) : (
        <>
          {listCard.length ? (
            <ul
              className={classNames(styles.titleDiv, {
                [styles.titleDivDecimal]: !isDote,
              })}
            >
              {listCard.map((value, index) => (
                <li key={index}>{value}</li>
              ))}
            </ul>
          ) : (
            <div>Список задач</div>
          )}
        </>
      )}
    </div>
  )
}
