// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TitleContent_titleDiv__1vXMZ {
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 30.5px; /* 122% */
  letter-spacing: -0.4px;
  word-wrap: break-word;
}

.TitleContent_title__BTx7J {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  outline: none;
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 30.5px; /* 122% */
  letter-spacing: -0.4px;
  border: none;
  padding: 0;
}

.TitleContent_innerSave__j1gaF {
  display: flex;
  justify-content: flex-end;
}

.TitleContent_save__95S2g {
  margin-top: 8px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  border-radius: 5px;
  background: #d9d9d9;
  padding: 4px 6px;
  cursor: pointer;
}
.TitleContent_save__95S2g:hover {
  background-color: #ffbdbd;
  transition: all 0.5s;
}`, "",{"version":3,"sources":["webpack://./src/components/TitleContent/TitleContent.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA,EAAA,SAAA;EACA,sBAAA;EACA,qBAAA;AACF;;AAEA;EACE,WAAA;EACA,wBAAA;EAAA,mBAAA;EACA,aAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA,EAAA,SAAA;EACA,sBAAA;EACA,YAAA;EACA,UAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;AACF;;AACA;EACE,eAAA;EACA,uBAAA;EAAA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AAEF;AADE;EACE,yBAAA;EACA,oBAAA;AAGJ","sourcesContent":[".titleDiv {\n  color: #000;\n  font-size: 25px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 30.5px; /* 122% */\n  letter-spacing: -0.4px;\n  word-wrap: break-word;\n}\n\n.title {\n  width: 100%;\n  height: fit-content;\n  outline: none;\n  color: #000;\n  font-size: 25px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 30.5px; /* 122% */\n  letter-spacing: -0.4px;\n  border: none;\n  padding: 0;\n}\n\n.innerSave {\n  display: flex;\n  justify-content: flex-end;\n}\n.save {\n  margin-top: 8px;\n  width: fit-content;\n  display: flex;\n  border-radius: 5px;\n  background: #d9d9d9;\n  padding: 4px 6px;\n  cursor: pointer;\n  &:hover {\n    background-color: #ffbdbd;\n    transition: all 0.5s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleDiv": `TitleContent_titleDiv__1vXMZ`,
	"title": `TitleContent_title__BTx7J`,
	"innerSave": `TitleContent_innerSave__j1gaF`,
	"save": `TitleContent_save__95S2g`
};
export default ___CSS_LOADER_EXPORT___;
