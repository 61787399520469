import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import RadioInput from '../RadioInput/RadioInput'
import testImg from '../../assets/img/test.jpg'
import styles from './TestA.module.scss'
import { getSaveTest, getTestA } from '../../redux/Client/clientSlice'
import { BiArrowBack } from 'react-icons/bi'
import classNames from 'classnames'

export const TestA = () => {
  const dispatch = useDispatch()
  const testRender = useSelector((state) => state.clientSlice.testA)
  const [test, setTest] = useState()
  const [inputValue, setInputValue] = useState(0)
  const { id, day } = useParams()
  const navigate = useNavigate()

  const saveTestHandler = () => {
    if (inputValue) {
      dispatch(
        getSaveTest({
          num: 0,
          userId: id,
          content: { test, inputValue: inputValue },
        })
      )
    } else return
  }

  useEffect(() => {
    dispatch(getTestA({ num: 0, userId: id }))
  }, [])

  useEffect(() => {
    setTest(testRender?.test)
  }, [testRender?.test])

  useEffect(() => {
    setInputValue(test?.filter((obj) => obj.answers === 'Да').length)
  }, [test])

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div
          className={styles.exit}
          onClick={() => navigate(`/diary-journal?day=${day}&userId=${id}`)}
        >
          <div className={styles.back}>
            <BiArrowBack />
            Назад
          </div>
        </div>
        <img
          className={styles.img}
          src={testImg}
          alt=""
        />
        <div className={styles.title}>
          Сделай глубокий вдох и честно ответь на вопросы:
        </div>
        {testRender?.test
          ? testRender?.test.map((value) => (
              <div
                key={value.id}
                className={styles.wrapQ}
              >
                <div className={styles.tetx}>{value.question}</div>
                <RadioInput
                  disabled={!!value.answers}
                  id={value.id}
                  valueArr={value.answers}
                  setTest={setTest}
                  test={test}
                />
              </div>
            ))
          : []}
        <div className={styles.text}>
          Твой резудьтат теста, в котором подсчитано сколько раз было ДА:
        </div>
        <div className={styles.inp}>{inputValue}</div>
        <div className={styles.text}>
          Это твой результат на сегодняшний день, твоя точка А в начале нашего
          курса. В конце мы проверим, получилось ли нам с тобой что-либо
          изменить🚀<br></br>
          Вам необходимо вернутся в Telegram и нажать кнопку "Готово".
        </div>
        {!testRender?.inputValue && (
          <div
            className={classNames(
              classNames(styles.btn, { [styles.btnDisable]: !inputValue })
            )}
            onClick={saveTestHandler}
          >
            Сохранить результат
          </div>
        )}
      </div>
    </div>
  )
}
