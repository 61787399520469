import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './DiaryJournal.module.scss'
import classNames from 'classnames'
import Done from './Done'
import { getTestA, getTestB } from '../../redux/Client/clientSlice'

export const DiaryJournal = () => {
  const dispatch = useDispatch()
  const [dayNum, setDayNum] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()

  const testB = useSelector((state) => state.clientSlice.testB)
  const testA = useSelector((state) => state.clientSlice.testA)

  const searchParams = new URLSearchParams(location.search)
  const day = Number(searchParams.get('day'))
  const id = searchParams.get('userId')

  useEffect(() => {
    dispatch(getTestA({ num: 0, userId: id }))
    dispatch(getTestB({ num: 31, userId: id }))
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <h1 className={styles.mumDay}>Мой дневник мыслей</h1>
        <div
          className={classNames(styles.day, {
            [styles.active]: dayNum === 0,
          })}
          onClick={() => {
            navigate(`/questions/a/${day}/${id}`)
            setDayNum(0)
          }}
        >
          <div>Тест А</div>
          {testA?.inputValue !== '' ? <Done /> : null}
        </div>
        {[...new Array(day <= 30 ? day : 30)].map((_, index) => (
          <div
            key={index}
            className={classNames(styles.day, {
              [styles.active]: dayNum === index + 1,
            })}
            onClick={() => {
              setDayNum(index + 1)
              navigate(`/diary-journal/${index + 1}/${id}/${day}`)
            }}
          >
            <div> {`День ${index + 1}`}</div>
            {index < day - 1 || (day === 30 && testB?.inputValue !== '') ? (
              <Done />
            ) : null}
          </div>
        ))}
        {day === 30 || day === 31 ? (
          <div
            className={classNames(styles.day, {
              [styles.active]: dayNum === 30 || day === 31,
            })}
            onClick={() => {
              navigate(`/questions/b/${day}/${id}`)
              setDayNum(31)
            }}
          >
            <div> Тест B</div>
            {testB?.inputValue !== '' ? <Done /> : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}
