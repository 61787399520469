import { createSlice } from '@reduxjs/toolkit'
import { dataArr } from '../../data.js'

const KEY_REGISTR_USER = 'keyAdmin'

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    mocArr: dataArr,
    valueDay: 0,
    registration: !!localStorage.getItem(KEY_REGISTR_USER),
    statusLogin: '',
    viewDiaryAdmin: [],
    kpi1: [],
    kpi2: [],
    kpi3: 0,
    kpi4: [],
    kpi5: [],
    kpi6: [],
  },
  reducers: {
    getSaveDayPhoto: (state, action) => {},
    getLoadDayPhoto: (state, action) => {},
    getLoginAdmin: (state, action) => {},
    setArr: (state, action) => {
      state.mocArr = action.payload
    },
    setValueDay: (state, action) => {
      state.valueDay = action.payload
    },
    setRegistration: (state, action) => {
      state.registration = action.payload
    },
    setStatusLogin: (state, action) => {
      state.statusLogin = action.payload
    },
    getSaveAdminDiary: (state, action) => {},
    getViewAdminDiary: (state, action) => {},
    setViewAdminDiary: (state, action) => {
      state.viewDiaryAdmin = action.payload
    },
    getKpi1AdminDiary: (state, action) => {},
    setKpi1AdminDiary: (state, action) => {
      state.kpi1 = action.payload
    },
    getKpi2AdminDiary: (state, action) => {},
    setKpi2AdminDiary: (state, action) => {
      state.kpi2 = action.payload
    },
    getKpi3AdminDiary: (state, action) => {},
    setKpi3AdminDiary: (state, action) => {
      state.kpi3 = action.payload
    },
    getKpi4AdminDiary: (state, action) => {},
    setKpi4AdminDiary: (state, action) => {
      state.kpi4 = action.payload
    },
    getKpi5AdminDiary: (state, action) => {},
    setKpi5AdminDiary: (state, action) => {
      state.kpi5 = action.payload
    },
    getKpi6AdminDiary: (state, action) => {},
    setKpi6AdminDiary: (state, action) => {
      state.kpi6 = action.payload
    },
  },
})

export const {
  getSaveDayPhoto,
  getLoadDayPhoto,
  setLoadDAyPhoto,
  setArr,
  setValueDay,
  getLoginAdmin,
  setRegistration,
  setStatusLogin,
  getSaveAdminDiary,
  getViewAdminDiary,
  setViewAdminDiary,
  getKpi1AdminDiary,
  setKpi1AdminDiary,
  getKpi2AdminDiary,
  setKpi2AdminDiary,
  getKpi3AdminDiary,
  setKpi3AdminDiary,
  getKpi4AdminDiary,
  setKpi4AdminDiary,
  getKpi5AdminDiary,
  setKpi5AdminDiary,
  getKpi6AdminDiary,
  setKpi6AdminDiary,
} = adminSlice.actions
export default adminSlice.reducer
