import classNames from 'classnames'

import styles from './Button.module.scss'

export const Button = (props) => {
  const { title, onClick, className, disabled } = props
  return (
    <button
      className={classNames(styles.btn, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  )
}
