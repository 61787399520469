import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTable } from 'react-table'
import styled from 'styled-components'
import styles from './Table.module.scss'
import { FcDeleteColumn, FcDeleteRow } from 'react-icons/fc'
import { setArr } from '../../redux/Admin/adminSlice'
import classNames from 'classnames'

const TableContainer = styled.div`
  max-width: 800px;
  overflow: auto;
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
`

const Th = styled.th`
  background-color: #f2f2f2;
  padding: 10px 25px;
  border: 1px solid #ddd;
  text-align: left;
  position: relative;
  font-size: 14px;
  text-align: center;
`

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
  width: fit-content;
`

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
`

const Button = styled.button`
  color: #fff;
  border-radius: 5px;
  background-color: rgb(168, 168, 168);
  padding: 4px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;
  font-size: 14px;
`

const Table = (props) => {
  const dispatch = useDispatch()
  const { editPanel, id, arr, tablCard } = props
  const [enterKeyPressed, setEnterKeyPressed] = useState(false)
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data })

  const [editCell, setEditCell] = useState(null)

  const addRow = () => {
    setData([...data, {}])
  }

  const addColumn = () => {
    const newColumn = {
      Header: `Столбец ${columns.length + 1}`,
      accessor: `column${columns.length + 1}`,
    }
    setColumns([...columns, newColumn])
  }

  const handleCellEdit = (rowIndex, columnId, value) => {
    const updatedData = [...data]
    updatedData[rowIndex] = {
      ...updatedData[rowIndex],
      [columnId]: value,
    }
    setData(updatedData)
  }

  const handleDeleteRow = (rowIndex) => {
    const updatedData = [...data]
    updatedData.splice(rowIndex, 1)
    setData(updatedData)
  }

  const handleEditColumn = (columnIndex, value) => {
    const updatedColumns = [...columns]
    updatedColumns[columnIndex].Header = value
    setColumns(updatedColumns)
  }

  const handleDeleteColumn = (columnIndex) => {
    const updatedColumns = [...columns]
    updatedColumns.splice(columnIndex, 1)
    setColumns(updatedColumns)
    setEditCell(null)
  }

  const saveData = () => {
    dispatch(
      setArr(
        arr.map((value) =>
          value.id === id
            ? {
                ...value,
                table: [{ row: data, col: columns }],
                edit: false,
              }
            : { ...value }
        )
      )
    )
  }

  useEffect(() => {
    if (tablCard.length) {
      setData(tablCard[0].row)
      setColumns(tablCard[0].col)
    } else {
      setData([])
      setColumns([
        { Header: 'Событие', accessor: 'event' },
        { Header: 'Мысли', accessor: 'thoughts' },
      ])
    }
  }, [tablCard])

  return (
    <>
      {editPanel ? (
        <div
          style={{
            display: 'flex',
            gap: '20px',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          <Button
            onClick={addRow}
            type="primary"
          >
            Добавить строку
          </Button>
          <Button
            onClick={addColumn}
            type="primary"
          >
            Добавить столбец
          </Button>
        </div>
      ) : null}

      <TableContainer>
        <div
          className={classNames(styles.wrapTable, {
            [styles.wrapTableActive]: !editPanel,
          })}
        >
          <StyledTable {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, groupIndex) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={groupIndex}
                >
                  {headerGroup.headers.map((column, columnIndex) => (
                    <Th {...column.getHeaderProps()}>
                      {editCell && editCell.columnIndex === columnIndex ? (
                        <div>
                          <Input
                            type="text"
                            value={column.Header}
                            onChange={(e) =>
                              handleEditColumn(columnIndex, e.target.value)
                            }
                            onBlur={() => setEditCell(null)}
                          />
                          <div
                            className={styles.deleteColl}
                            onClick={() => handleDeleteColumn(columnIndex)}
                          >
                            <FcDeleteColumn />
                          </div>
                        </div>
                      ) : (
                        <div onClick={() => setEditCell({ columnIndex })}>
                          {column.render('Header')}
                          {editPanel ? (
                            <div
                              className={styles.deleteColl}
                              onClick={() => handleDeleteColumn(columnIndex)}
                            >
                              <FcDeleteColumn />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </Th>
                  ))}
                  {editPanel ? <Th>Опции</Th> : null}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    key={rowIndex}
                  >
                    {row.cells.map((cell, cellIndex) => (
                      <Td
                        {...cell.getCellProps()}
                        onClick={() =>
                          setEditCell({
                            rowIndex,
                            columnId: columns[cellIndex].accessor,
                          })
                        }
                        key={cellIndex}
                      >
                        {editCell &&
                        editCell.rowIndex === rowIndex &&
                        editCell.columnId === columns[cellIndex].accessor ? (
                          <Input
                            type="text"
                            value={cell.value}
                            onChange={(e) =>
                              handleCellEdit(
                                rowIndex,
                                columns[cellIndex].accessor,
                                e.target.value
                              )
                            }
                            onBlur={() => setEditCell(null)}
                          />
                        ) : (
                          cell.render('Cell')
                        )}
                      </Td>
                    ))}
                    {editPanel ? (
                      <Td>
                        <div
                          className={styles.delRow}
                          onClick={() => handleDeleteRow(rowIndex)}
                        >
                          <FcDeleteRow />
                        </div>
                      </Td>
                    ) : null}
                  </tr>
                )
              })}
            </tbody>
          </StyledTable>
        </div>
      </TableContainer>
      {editPanel ? (
        <div className={styles.innerSave}>
          <div
            onClick={saveData}
            className={styles.save}
          >
            Сохранить
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Table
