// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlockDay_inner__FIQ5J {
  width: 201px;
  height: 1040px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.BlockDay_btnA__aS1Lj {
  margin-bottom: 43px;
}

.BlockDay_active__\\+NfEP {
  background-color: #ffbdbd;
}

.BlockDay_back__UMgx4 {
  background-color: rgba(54, 162, 235, 0.2);
  padding: 17px;
  border-radius: 30px;
  min-width: 201px;
  color: #000;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.4px;
  transition: all 0.5s;
  border: none;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/BlockDay/BlockDay.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yCAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,oBAAA;EACA,YAAA;EACA,eAAA;AACF","sourcesContent":[".inner {\n  width: 201px;\n  height: 1040px;\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n  gap: 14px;\n}\n\n.btnA {\n  margin-bottom: 43px;\n}\n\n.active {\n  background-color: #ffbdbd;\n}\n\n.back {\n  background-color: rgba(54, 162, 235, 0.2);\n  padding: 17px;\n  border-radius: 30px;\n  min-width: 201px;\n  color: #000;\n  text-align: center;\n  font-size: 17px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.4px;\n  transition: all 0.5s;\n  border: none;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `BlockDay_inner__FIQ5J`,
	"btnA": `BlockDay_btnA__aS1Lj`,
	"active": `BlockDay_active__+NfEP`,
	"back": `BlockDay_back__UMgx4`
};
export default ___CSS_LOADER_EXPORT___;
