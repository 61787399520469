// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalLogin_wrap__RBqiQ {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ModalLogin_inner__M8sFj {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 1440px;
  overflow: auto;
  height: 300px;
  border-radius: 30px;
  border: 0.5px solid #bfc1c8;
  background: #fff;
  box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
  padding: 40px 80px;
  margin-bottom: 20px;
}

.ModalLogin_input__kDzfG {
  width: 100%;
  padding: 10px 10px 10px 23px;
  border-radius: 12px;
  border: 0.6px solid #e6edf3;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
  outline: none;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
.ModalLogin_input__kDzfG:focus {
  border: 0.6px solid #094e91;
}

.ModalLogin_button__qMd64 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  transition: all 0.5s;
  background-color: #094e91;
  border: 1px solid rgba(0, 0, 0, 0.6);
  padding: 20px 10px;
  color: white;
}
.ModalLogin_button__qMd64:hover {
  cursor: pointer;
  transition: all 0.5s;
  background-color: #186cbd;
}`, "",{"version":3,"sources":["webpack://./src/components/ModalLogin/ModalLogin.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,iBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,gBAAA;EACA,8FAAA;EAEA,kBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,WAAA;EACA,4BAAA;EACA,mBAAA;EACA,2BAAA;EACA,oDAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAAF;AACE;EACE,2BAAA;AACJ;;AAGA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,oBAAA;EACA,yBAAA;EACA,oCAAA;EACA,kBAAA;EACA,YAAA;AAAF;AACE;EACE,eAAA;EACA,oBAAA;EACA,yBAAA;AACJ","sourcesContent":[".wrap {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.inner {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 20px;\n  max-width: 1440px;\n  overflow: auto;\n  height: 300px;\n  border-radius: 30px;\n  border: 0.5px solid #bfc1c8;\n  background: #fff;\n  box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08),\n    0px 6px 12px -6px rgba(24, 39, 75, 0.12);\n  padding: 40px 80px;\n  margin-bottom: 20px;\n}\n\n.input {\n  width: 100%;\n  padding: 10px 10px 10px 23px;\n  border-radius: 12px;\n  border: 0.6px solid #e6edf3;\n  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12);\n  outline: none;\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 30px;\n  &:focus {\n    border: 0.6px solid #094e91;\n  }\n}\n\n.button {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 5px;\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 19px;\n  text-align: center;\n  transition: all 0.5s;\n  background-color: #094e91;\n  border: 1px solid rgba(0, 0, 0, 0.6);\n  padding: 20px 10px;\n  color: white;\n  &:hover {\n    cursor: pointer;\n    transition: all 0.5s;\n    background-color: #186cbd;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `ModalLogin_wrap__RBqiQ`,
	"inner": `ModalLogin_inner__M8sFj`,
	"input": `ModalLogin_input__kDzfG`,
	"button": `ModalLogin_button__qMd64`
};
export default ___CSS_LOADER_EXPORT___;
