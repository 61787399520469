import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const VerticalDiagram = (props) => {
  const { data } = props

  return (
    <Bar
      data={data}
      options={{
        aspectRatio: 3,
      }}
    />
  )
}

export default VerticalDiagram
