import classNames from 'classnames'


import styles from './Loader.module.scss'

const Loader = ({ className }) => {
  return (
    <div className={classNames(styles.wrap, className)}>
      <div className={styles.ldsRipple}>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
export default Loader
