import React, { useState } from 'react'
import { Radio } from 'antd'
import styles from './RadioInput.module.scss'

const RadioInput = (props) => {
  const { setTest, id, valueArr, disabled, test } = props
  const [value, setValue] = useState(valueArr)
  const onChange = (e) => {
    setTest(
      test.map((el) =>
        el.id === id ? { ...el, answers: e.target.value } : { ...el }
      )
    )
    setValue(e.target.value)
  }

  return (
    <Radio.Group
      onChange={onChange}
      value={value}
      id={id}
      disabled={disabled}
    >
      <Radio
        value={'Да'}
        className={styles.input}
      >
        Да
      </Radio>
      <Radio
        value={'Нет'}
        className={styles.input}
      >
        Нет
      </Radio>
    </Radio.Group>
  )
}
export default RadioInput
