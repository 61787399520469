import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../UI/Button/Button'
import styles from './BlockDay.module.scss'
import classNames from 'classnames'
import { day } from '../../data'
import {
  getLoadDayPhoto,
  setValueDay,
  setArr,
  getViewAdminDiary,
} from '../../redux/Admin/adminSlice'
import { dataArr } from '../../data'
import { useNavigate } from 'react-router-dom'

export const BlockDay = (props) => {
  const { selectAdmin, setSelectAdmin } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const valueDay = useSelector((state) => state.adminSlice.valueDay)

  const getDay = (value) => {
    if (selectAdmin === 'Jiurnal') {
      if (value === 0 || value === 31) {
        dispatch(setValueDay(value))
      } else {
        dispatch(setValueDay(value))
        dispatch(getViewAdminDiary({ day: value }))
      }
    } else if (selectAdmin === 'Day') {
      dispatch(getLoadDayPhoto(value))
      dispatch(setValueDay(value))
    }
  }
  const getSampel = () => {
    dispatch(setArr(dataArr))
    dispatch(setValueDay(0))
  }
  return (
    <div className={styles.inner}>
      <div
        className={styles.back}
        onClick={() => setSelectAdmin('')}
      >
        Назад
      </div>
      {selectAdmin === 'Day' && (
        <Button
          onClick={() => {
            navigate('stat')
            dispatch(setValueDay(-1))
          }}
          title="Статистика"
          className={classNames(styles.btnA, {
            [styles.active]: valueDay === -1,
          })}
        />
      )}
      {selectAdmin === 'Day' && (
        <Button
          onClick={getSampel}
          title="Шаблон дня"
          className={classNames(styles.btnA, {
            [styles.active]: valueDay === 0,
          })}
        />
      )}
      {selectAdmin === 'Jiurnal' && (
        <Button
          onClick={() => getDay(0)}
          title="Тест А"
          className={classNames(styles.btnA, {
            [styles.active]: valueDay === 0,
          })}
        />
      )}
      {day.map((value, index) => (
        <Button
          className={classNames({ [styles.active]: value.value === valueDay })}
          onClick={() => getDay(value.value)}
          key={index}
          title={`${value.value} день`}
        />
      ))}
      {selectAdmin === 'Jiurnal' && (
        <Button
          onClick={() => getDay(31)}
          title="Тест В"
          className={classNames(styles.btnA, {
            [styles.active]: valueDay === 31,
          })}
        />
      )}
    </div>
  )
}
