import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  getSaveDayPhoto,
  getLoadDayPhoto,
  setArr,
  getLoginAdmin,
  setRegistration,
  setStatusLogin,
  getSaveAdminDiary,
  getViewAdminDiary,
  getKpi1AdminDiary,
  setKpi1AdminDiary,
  getKpi2AdminDiary,
  setKpi2AdminDiary,
  getKpi3AdminDiary,
  setKpi3AdminDiary,
  getKpi4AdminDiary,
  setKpi4AdminDiary,
  getKpi5AdminDiary,
  setKpi5AdminDiary,
  getKpi6AdminDiary,
  setKpi6AdminDiary,
} from '../Admin/adminSlice'
import { toast } from 'react-toastify'
import API from '../utils/API'
import { dataArr } from '../../data'

const KEY_REGISTR_USER = 'keyAdmin'

function* saveDayWorker(action) {
  const token = localStorage.getItem(KEY_REGISTR_USER) || ''
  const { data, ok } = yield call(API.saveDay, action.payload, token)
  if (ok) {
    toast.success('Информация успешна сохранена!')
  } else {
    if (data?.message === 'Token is invalid !!') {
      yield put(setRegistration(false))
      localStorage.removeItem(KEY_REGISTR_USER)
      toast.error('Сессия Вашего токена истекла! Войдите еще раз.')
    } else {
      toast.error('Не удалось сохранить информацию. Попробуйте еще раз!')
    }
  }
}

function* loadDayWorker(action) {
  const token = localStorage.getItem(KEY_REGISTR_USER) || ''
  const { data, ok } = yield call(API.loadDayAdmin, action.payload, token)
  if (data && ok) {
    if (data === 'Day not found') {
      yield put(setArr(dataArr))
      toast.success('Информации по данному дню не имеется!')
    } else {
      yield put(setArr(data))
      toast.success('Информация успешно загружена!')
    }
  } else {
    yield put(setArr(dataArr))
    toast.error('Не удалось загрузить информацию. Попробуйте еще раз!')
  }
}

function* loginAdminWorker(action) {
  yield put(setStatusLogin('pending'))
  const { data, ok } = yield call(API.loginAdmin, action.payload)
  if (ok) {
    yield put(setStatusLogin('fullfilled'))
    localStorage.setItem(KEY_REGISTR_USER, data)
    yield put(setRegistration(true))
    toast.success('Вы успешно вошли!')
  } else {
    yield put(setStatusLogin('regected'))
    yield put(setRegistration(false))
    toast.error('Не правильный логин или пароль. Попробуйте еще раз!')
  }
}

function* saveDiaryAdminWorker(action) {
  const token = localStorage.getItem(KEY_REGISTR_USER) || ''
  const { ok, data } = yield call(API.saveDiaryAdmin, action.payload, token)
  if (ok) {
    toast.success('Информация успешна сохранена!')
  } else {
    if (data?.message === 'Token is invalid !!') {
      yield put(setRegistration(false))
      localStorage.removeItem(KEY_REGISTR_USER)
      toast.error('Сессия Вашего токена истекла! Войдите еще раз.')
    } else {
      toast.error('Не удалось сохранить информацию. Попробуйте еще раз!')
    }
  }
}

function* loadDiaryAdminWorker(action) {
  const token = localStorage.getItem(KEY_REGISTR_USER) || ''
  const { data, ok } = yield call(API.loadDiaryAdmin, action.payload, token)
  if (data && ok) {
    if (data === 'Day not found') {
      // yield put(setViewAdminDiary(dataArr))
      yield put(setArr(dataArr))
    } else {
      // yield put(setViewAdminDiary(data))
      yield put(setArr(data))
      toast.success('Информация успешно загружена!')
    }
  } else {
    if (data?.message === 'Token is invalid !!') {
      yield put(setRegistration(false))
      localStorage.removeItem(KEY_REGISTR_USER)
      toast.error('Сессия Вашего токена истекла! Войдите еще раз.')
    } else {
      toast.error('Не удалось сохранить информацию. Попробуйте еще раз!')
    }
  }
}

function* getKpi1AdminWorker(action) {
  const token = localStorage.getItem(KEY_REGISTR_USER) || ''
  const { ok, data } = yield call(API.getKpi1Admin, token)
  if (ok) {
    yield put(setKpi1AdminDiary(data))
  } else {
    yield put(setKpi1AdminDiary([]))
    toast.error('Не удалось загрузить информацию. Попробуйте еще раз!')
  }
}

function* getKpi2AdminWorker(action) {
  const token = localStorage.getItem(KEY_REGISTR_USER) || ''
  const { ok, data } = yield call(API.getKpi2Admin, token)
  if (ok) {
    yield put(setKpi2AdminDiary(data))
  } else {
    yield put(setKpi2AdminDiary([]))
    toast.error('Не удалось загрузить информацию. Попробуйте еще раз!')
  }
}

function* getKpi3AdminWorker(action) {
  const token = localStorage.getItem(KEY_REGISTR_USER) || ''
  const { ok, data } = yield call(API.getKpi3Admin, token)
  if (ok) {
    yield put(setKpi3AdminDiary(data))
  } else {
    yield put(setKpi3AdminDiary(0))
    toast.error('Не удалось загрузить информацию. Попробуйте еще раз!')
  }
}

function* getKpi4AdminWorker(action) {
  const token = localStorage.getItem(KEY_REGISTR_USER) || ''
  const { ok, data } = yield call(API.getKpi4Admin, token)
  if (ok) {
    yield put(setKpi4AdminDiary(data))
  } else {
    yield put(setKpi4AdminDiary([]))
    toast.error('Не удалось загрузить информацию. Попробуйте еще раз!')
  }
}

function* getKpi5AdminWorker(action) {
  const token = localStorage.getItem(KEY_REGISTR_USER) || ''
  const { ok, data } = yield call(API.getKpi5Admin, token)
  if (ok) {
    yield put(setKpi5AdminDiary(data?.refered_from))
  } else {
    yield put(setKpi5AdminDiary([]))
    toast.error('Не удалось загрузить информацию. Попробуйте еще раз!')
  }
}
function* getKpi6AdminWorker(action) {
  const token = localStorage.getItem(KEY_REGISTR_USER) || ''
  const { ok, data } = yield call(API.getKpi6Admin, token)
  if (ok) {
    yield put(setKpi6AdminDiary(data))
  } else {
    yield put(setKpi6AdminDiary([]))
    toast.error('Не удалось загрузить информацию. Попробуйте еще раз!')
  }
}

export default function* userSaga() {
  yield all([takeLatest(getSaveDayPhoto, saveDayWorker)])
  yield all([takeLatest(getLoadDayPhoto, loadDayWorker)])
  yield all([takeLatest(getLoginAdmin, loginAdminWorker)])
  yield all([takeLatest(getSaveAdminDiary, saveDiaryAdminWorker)])
  yield all([takeLatest(getViewAdminDiary, loadDiaryAdminWorker)])
  yield all([takeLatest(getKpi1AdminDiary, getKpi1AdminWorker)])
  yield all([takeLatest(getKpi2AdminDiary, getKpi2AdminWorker)])
  yield all([takeLatest(getKpi3AdminDiary, getKpi3AdminWorker)])
  yield all([takeLatest(getKpi4AdminDiary, getKpi4AdminWorker)])
  yield all([takeLatest(getKpi5AdminDiary, getKpi5AdminWorker)])
  yield all([takeLatest(getKpi6AdminDiary, getKpi6AdminWorker)])
}
