// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateTest_input__6c15L {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .CreateTest_input__6c15L {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .CreateTest_input__6c15L {
    font-size: 14px;
  }
}
.CreateTest_innerSave__XlZez {
  display: flex;
  justify-content: flex-end;
}

.CreateTest_save__tP0sx {
  margin-top: 8px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  border-radius: 5px;
  background: #d9d9d9;
  padding: 4px 6px;
  cursor: pointer;
}
.CreateTest_save__tP0sx:hover {
  background-color: #ffbdbd;
  transition: all 0.5s;
}`, "",{"version":3,"sources":["webpack://./src/components/CreateTest/CreateTest.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,wBAAA;EAAA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE;IACE,eAAA;EACF;AACF;AAEA;EACE;IACE,eAAA;EAAF;AACF;AAGA;EACE,aAAA;EACA,yBAAA;AADF;;AAGA;EACE,eAAA;EACA,uBAAA;EAAA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AAAF;AACE;EACE,yBAAA;EACA,oBAAA;AACJ","sourcesContent":[".input {\n  width: 100%;\n  height: fit-content;\n  color: #000;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 27px;\n  border: 1px solid #d9d9d9;\n  padding: 10px;\n  border-radius: 20px;\n  margin-bottom: 20px;\n  margin-top: 10px;\n}\n\n@media (max-width: 768px) {\n  .input {\n    font-size: 16px;\n  }\n}\n\n@media (max-width: 400px) {\n  .input {\n    font-size: 14px;\n  }\n}\n\n.innerSave {\n  display: flex;\n  justify-content: flex-end;\n}\n.save {\n  margin-top: 8px;\n  width: fit-content;\n  display: flex;\n  border-radius: 5px;\n  background: #d9d9d9;\n  padding: 4px 6px;\n  cursor: pointer;\n  &:hover {\n    background-color: #ffbdbd;\n    transition: all 0.5s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `CreateTest_input__6c15L`,
	"innerSave": `CreateTest_innerSave__XlZez`,
	"save": `CreateTest_save__tP0sx`
};
export default ___CSS_LOADER_EXPORT___;
