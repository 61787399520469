// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Statistic_wrap__qWl44 {
  max-width: 1920px;
  padding: 20px 50px;
  margin: 0 auto;
}

.Statistic_title__Qb5rL {
  max-width: 900px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #032d60;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-bottom: 50px;
}
.Statistic_title__Qb5rL span {
  position: absolute;
  left: 0;
}
.Statistic_title__Qb5rL svg {
  cursor: pointer;
}

.Statistic_titleItem__SsorQ {
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 10px;
}

.Statistic_diagram__-e44B {
  max-width: 900px;
  margin: 0 auto 100px;
}
.Statistic_diagram__-e44B a {
  text-decoration: none;
  color: rgb(54, 162, 235);
}

.Statistic_number__me96- {
  color: #d9edfc;
  font-size: 90px;
  text-align: center;
}

.Statistic_table__rd\\+ym {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #dddddd;
  border-collapse: collapse;
}

.Statistic_table__rd\\+ym th {
  font-weight: bold;
  padding: 5px;
  background: #efefef;
  border: 1px solid #dddddd;
}

.Statistic_table__rd\\+ym td {
  border: 1px solid #dddddd;
  padding: 5px;
}

.Statistic_innerCsv__n2Q7F {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Statistic/Statistic.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,cAAA;AACF;;AAEA;EACE,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;EACA,OAAA;AACJ;AACE;EACE,eAAA;AACJ;;AAGA;EACE,uBAAA;EAAA,kBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,oBAAA;AAAF;AACE;EACE,qBAAA;EACA,wBAAA;AACJ;;AAGA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;AAAF;;AAGA;EACE,WAAA;EACA,mBAAA;EACA,yBAAA;EACA,yBAAA;AAAF;;AAEA;EACE,iBAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;AACF;;AACA;EACE,yBAAA;EACA,YAAA;AAEF;;AACA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;AAEF","sourcesContent":[".wrap {\n  max-width: 1920px;\n  padding: 20px 50px;\n  margin: 0 auto;\n}\n\n.title {\n  max-width: 900px;\n  margin: 0 auto;\n  position: relative;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  color: #032d60;\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  text-align: center;\n  margin-bottom: 50px;\n\n  span {\n    position: absolute;\n    left: 0;\n  }\n  svg {\n    cursor: pointer;\n  }\n}\n\n.titleItem {\n  width: fit-content;\n  margin-bottom: 10px;\n}\n\n.diagram {\n  max-width: 900px;\n  margin: 0 auto 100px;\n  a {\n    text-decoration: none;\n    color: rgba(54, 162, 235, 1);\n  }\n}\n\n.number {\n  color: #d9edfc;\n  font-size: 90px;\n  text-align: center;\n}\n\n.table {\n  width: 100%;\n  margin-bottom: 20px;\n  border: 1px solid #dddddd;\n  border-collapse: collapse;\n}\n.table th {\n  font-weight: bold;\n  padding: 5px;\n  background: #efefef;\n  border: 1px solid #dddddd;\n}\n.table td {\n  border: 1px solid #dddddd;\n  padding: 5px;\n}\n\n.innerCsv {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Statistic_wrap__qWl44`,
	"title": `Statistic_title__Qb5rL`,
	"titleItem": `Statistic_titleItem__SsorQ`,
	"diagram": `Statistic_diagram__-e44B`,
	"number": `Statistic_number__me96-`,
	"table": `Statistic_table__rd+ym`,
	"innerCsv": `Statistic_innerCsv__n2Q7F`
};
export default ___CSS_LOADER_EXPORT___;
