import { React, useState } from 'react'
import ImageUploading from 'react-images-uploading'
import { useDispatch } from 'react-redux'
import { setArr } from '../../redux/Admin/adminSlice'

import { GrUpdate } from 'react-icons/gr'
import { AiOutlineClose } from 'react-icons/ai'

import styles from './ImagesContent.module.scss'
import classNames from 'classnames'

function ImagesContent(props) {
  const { edit, id, imagesCard, arr } = props
  const dispatch = useDispatch()
  const [images, setImages] = useState([])
  const maxNumber = 69
  const onChange = (imageList) => {
    setImages(imageList)
    dispatch(
      setArr(
        arr.map((value) =>
          value.id === id
            ? {
                ...value,
                images: imageList[0]?.data_url,
                edit: false,
              }
            : { ...value }
        )
      )
    )
  }

  return (
    <div className={styles.wrap}>
      <ImageUploading
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={['jpg', 'gif', 'png']}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div
            className={classNames(styles.uploadImageWrapper, {
              [styles.noImg]: !images.length,
            })}
          >
            {!imagesCard ? (
              <button
                className={styles.btn}
                style={isDragging ? { color: 'red' } : null}
                onClick={onImageUpload}
                {...dragProps}
              ></button>
            ) : (
              <div className={styles.imageItem}>
                <img
                  className={styles.img}
                  src={imagesCard}
                  alt=""
                />
              </div>
            )}
            {edit ? (
              <div className={styles.imageItemBtnWrapper}>
                <button onClick={() => onImageUpdate()}>
                  <GrUpdate />
                </button>
                <button onClick={() => onImageRemove()}>
                  <AiOutlineClose />
                </button>
              </div>
            ) : null}
          </div>
        )}
      </ImageUploading>
    </div>
  )
}

export default ImagesContent
