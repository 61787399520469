import classNames from 'classnames'
import styles from './Modal.module.scss'

export const Modal = (props) => {
  const { isOpenModal, setIsOpenModal, addCardCleenHandler } = props
  const fnName = [
    'Текстовый блок',
    'Заголовок',
    'Загрузить изображение',
    'Таблица',
    'Список',
  ]
  return (
    <div
      className={classNames(styles.wrap, {
        [styles.showModal]: isOpenModal.isOpen,
      })}
    >
      {fnName.map((value, index) => (
        <div
          onClick={() => {
            addCardCleenHandler(value)
            setIsOpenModal({ isOpen: false, id: 0, array: '' })
          }}
          key={index}
          className={styles.item}
        >
          {value}
        </div>
      ))}
    </div>
  )
}
