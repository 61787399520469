// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table_innerDeleteColl__eWbFi {
  position: relative;
}

.Table_deleteColl__X7gxT {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.Table_delRow__V2Ymk {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.Table_save__QoiBU {
  color: #fff;
  border-radius: 5px;
  background-color: rgb(168, 168, 168);
  padding: 4px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;
  font-size: 14px;
}
.Table_save__QoiBU:hover {
  background-color: #ffbdbd;
  transition: all 0.5s;
}

.Table_wrapTableActive__7LM8X {
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Table/Table.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,oCAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,oBAAA;EACA,eAAA;AACF;AAAE;EACE,yBAAA;EACA,oBAAA;AAEJ;;AAEA;EACE,oBAAA;AACF","sourcesContent":[".innerDeleteColl {\n  position: relative;\n}\n\n.deleteColl {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n}\n\n.delRow {\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n}\n\n.save {\n  color: #fff;\n  border-radius: 5px;\n  background-color: rgb(168, 168, 168);\n  padding: 4px 6px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  transition: all 0.5s;\n  font-size: 14px;\n  &:hover {\n    background-color: #ffbdbd;\n    transition: all 0.5s;\n  }\n}\n\n.wrapTableActive {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerDeleteColl": `Table_innerDeleteColl__eWbFi`,
	"deleteColl": `Table_deleteColl__X7gxT`,
	"delRow": `Table_delRow__V2Ymk`,
	"save": `Table_save__QoiBU`,
	"wrapTableActive": `Table_wrapTableActive__7LM8X`
};
export default ___CSS_LOADER_EXPORT___;
