// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_wrap__gVM-6 {
  position: relative;
  border-radius: 30px;
  background: #fff;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  padding: 15px 20px;
}

.Card_wrapImg__Mg7XE {
  background-color: #a8a8a8;
  width: 390px;
  height: 265px;
}

.Card_control__mTdui {
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.Card_controlBtn__GfUut {
  width: 33px;
  height: 28px;
  border-radius: 5px;
  background: #d9d9d9;
  padding: 4px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;
}
.Card_controlBtn__GfUut:hover {
  background-color: #ffbdbd;
  transition: all 0.5s;
}

.Card_active__AWKeI {
  background-color: #ffbdbd;
}

.Card_disabled__skGnX {
  pointer-events: none;
  opacity: 80%;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/FormContainer/Card.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,yBAAA;EACA,YAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,QAAA;EACA,yBAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,oBAAA;AACF;AAAE;EACE,yBAAA;EACA,oBAAA;AAEJ;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,oBAAA;EACA,YAAA;AACF","sourcesContent":[".wrap {\n  position: relative;\n  border-radius: 30px;\n  background: #fff;\n  height: fit-content;\n  width: 100%;\n  padding: 15px 20px;\n}\n\n.wrapImg {\n  background-color: #a8a8a8;\n  width: 390px;\n  height: 265px;\n}\n\n.control {\n  display: flex;\n  gap: 4px;\n  justify-content: flex-end;\n  margin-bottom: 8px;\n}\n\n.controlBtn {\n  width: 33px;\n  height: 28px;\n  border-radius: 5px;\n  background: #d9d9d9;\n  padding: 4px 6px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  transition: all 0.5s;\n  &:hover {\n    background-color: #ffbdbd;\n    transition: all 0.5s;\n  }\n}\n\n.active {\n  background-color: #ffbdbd;\n}\n\n.disabled {\n  pointer-events: none;\n  opacity: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Card_wrap__gVM-6`,
	"wrapImg": `Card_wrapImg__Mg7XE`,
	"control": `Card_control__mTdui`,
	"controlBtn": `Card_controlBtn__GfUut`,
	"active": `Card_active__AWKeI`,
	"disabled": `Card_disabled__skGnX`
};
export default ___CSS_LOADER_EXPORT___;
