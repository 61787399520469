import React from 'react'

const Done = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <circle
        cx="18"
        cy="18"
        r="18"
        fill="#F8C5C0"
      />
      <line
        x1="11.6508"
        y1="17.2407"
        x2="18.6508"
        y2="23.2407"
        stroke="#555555"
        strokeWidth="2"
      />
      <line
        x1="17.226"
        y1="23.3668"
        x2="26.226"
        y2="12.3668"
        stroke="#555555"
        strokeWidth="2"
      />
    </svg>
  )
}

export default Done
