// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressBar_progressContainer__u5fYs {
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background: #f8c5c0;
  position: relative;
  margin-bottom: 21px;
}

.ProgressBar_progressBar__8d4AF {
  height: 100%;
  border-radius: 10px;
  background: #e85a4f;
  transition: width 0.3s ease-in-out;
}

.ProgressBar_dayNumContener__stfQU {
  display: flex;
  justify-content: space-between;
  font-family: "Heli";
  color: #555;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/ProgressBar/ProgressBar.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,kCAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF","sourcesContent":[".progressContainer {\n  width: 100%;\n  height: 10px;\n  border-radius: 10px;\n  background: #f8c5c0;\n  position: relative;\n  margin-bottom: 21px;\n}\n\n.progressBar {\n  height: 100%;\n  border-radius: 10px;\n  background: #e85a4f;\n  transition: width 0.3s ease-in-out;\n}\n\n.dayNumContener {\n  display: flex;\n  justify-content: space-between;\n  font-family: 'Heli';\n  color: #555;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressContainer": `ProgressBar_progressContainer__u5fYs`,
	"progressBar": `ProgressBar_progressBar__8d4AF`,
	"dayNumContener": `ProgressBar_dayNumContener__stfQU`
};
export default ___CSS_LOADER_EXPORT___;
