import React from 'react'
import { Select } from 'antd'
import styles from './Selected.module.scss'

const Selected = (props) => {
  const { handleChange, data, value } = props
  return (
    <Select
      className={styles.sel}
      defaultValue={value}
      style={{
        width: '100%',
      }}
      onChange={handleChange}
      options={data}
    />
  )
}

export default Selected
